import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { getBearerSelector } from "../../selectors";
import {getPartnersAction} from "../../actions";
import { API_URL } from "../Url";

export const useFetchPartner = () => {
  const bearer = useSelector(getBearerSelector);
  const dispatch = useDispatch();

  const getPartners = async () => {
    try {
      const response = await axios.get(`${API_URL}/partners`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `bearer ${bearer}`,
        },
      });
      dispatch(getPartnersAction(response.data))
      return response.data;
    } catch (e) {
      // return []
    }
  };

  return {
    getPartners,
  };
};
