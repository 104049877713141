import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  getBearerAction,
  getDocumentsAction,
  getNewsAction,
  getNewsCategoriesAction,
  getPhotosAction,
  getSelectedNewsAction,
  getUserAction,
  setProjectSelectedAction,
  setRemindersAction,
  setRemindersTotalAction,
  setRemindersCheckedAction
} from "../../actions";
import { API_URL } from "../Url";
import {
  getBearerSelector,
  getRemindersCheckedSelector,
  getRemindersSelector,
  getRemindersTotalSelector,
} from "../../selectors";

export const useFetchAuth = () => {
  const dispatch = useDispatch();
  const bearer = useSelector(getBearerSelector);

  const getApiBearer = async (username, password) => {
    try {
      const response = await axios.post(`${API_URL}/login_check`, {
        username,
        password,
      });
      dispatch(getBearerAction(response.data.token));
      return getApiUser(response.data.token);
    } catch (error) {
      return false;
    }
  };

  const getApiUser = async (bearer) => {
    try {
      const response = await axios.get(`${API_URL}/user`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `bearer ${bearer}`,
        },
      });
      dispatch(getUserAction(response.data));
      return true;
    } catch (error) {
      logout();
    }
  };

  const updatePassword = async (password) => {
    try {
      await axios.patch(
        `${API_URL}/user`,
        { password },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `bearer ${bearer}`,
          },
        }
      );
      return true;
    } catch (error) {
      return false;
    }
  };

  const updatePerson = async (person) => {
    try {
      await axios.patch(
        `${API_URL}/user/person/${person.token}`,
        {
          email: person.email,
          mobile: person.mobile,
        },
        {
          headers: {
            Authorization: `bearer ${bearer}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      getApiUser(bearer);
    } catch (e) {
      return false;
    }
  };

  const logout = () => {
    dispatch(getBearerAction(null));
    dispatch(getUserAction(null));
    dispatch(setProjectSelectedAction(null));
    dispatch(getNewsAction([]));
    dispatch(getSelectedNewsAction([]));
    dispatch(getNewsCategoriesAction([]));
    dispatch(getDocumentsAction([]));
    dispatch(getPhotosAction([]));
    dispatch(setRemindersAction([]))
    dispatch(setRemindersTotalAction(0));
    dispatch(setRemindersCheckedAction(0));
    localStorage.removeItem("bearer");
    localStorage.removeItem("user");
    localStorage.removeItem("persons");
    localStorage.removeItem("projects");
    localStorage.removeItem("projectSelected");
    localStorage.removeItem("news");
    localStorage.removeItem("selectedNews");
    localStorage.removeItem("newsCategories");
  };

  return {
    getApiBearer,
    getApiUser,
    updatePassword,
    updatePerson,
    logout,
  };
};
