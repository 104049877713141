import { GET_VIDEOS, GET_SELECTED_VIDEO } from "../actions/actionsType";

const defaultState = {
  videos: [],
  selectedVideo: null,
};

export const videoReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_VIDEOS:
      return {
        videos: action.payload.data,
        selectedVideo: state.selectedVideo,
      };
    case GET_SELECTED_VIDEO:
      return {
        videos: state.videos,
        selectedVideo: action.payload.data,
      };
    default:
      return state;
  }
};
