import Breadcrumb from "../Common/Breadcrumb";
import Categories from "./Categories";

const News = () => {
  //console.log("====================================");
  //console.log("News()");

  return (
    <>
      <Breadcrumb
        icon="tag"
        title="Des idées tendance !"
        links={[{ name: "Toutes nos actualités" }]}
      />
      <Categories />
    </>
  );
};

export default News;
