import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  getProjectSelectedSelector,
  getEventsSelector,
} from "../../../redux/selectors";
import { useFetchEvent } from "../../../redux/api/event/useFetchEvent";
// import Timeline from "./Calendar/Timeline";

import Event from "./Event";

export default function EventTypes() {
  const { getApiEvents } = useFetchEvent();
  const project = useSelector(getProjectSelectedSelector);
  const events = useSelector(getEventsSelector);

  useEffect(() => {
    getApiEvents(project);
  }, [project]);

  const types = events ? events.filter((type) => type.isStep) : [];
  return (
    <div>
      {types.length &&
        types.map((type, index) => (
          <Event
            data={type}
            key={type.token}
            isLast={index === types.length - 1}
          />
        ))}
    </div>
  );
}
