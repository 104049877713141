import { useState } from "react";
import { useFetchReminder } from "../../../redux/api/reminder/useFetchReminder";
import Checkbox from "./Checkbox";
import { Link } from "react-router-dom";

const Reminder = ({ data }) => {
  const { setApiReminderChecked } = useFetchReminder();
  const [isChecked, setIsChecked] = useState(data.isChecked);

  const handleCheckbox = () => {
    setIsChecked(!isChecked);
    setApiReminderChecked(data.id);
  };

  return (
    <div className="mb20">
      <div className="bold mb5">{data.title}</div>
      <div className={data.link ? "mb0" : "mb10"}>{data.text}</div>
      {data.link && (
        <div className="mb10">
          <Link to={data.link} target="_blank">
            {data.link}
          </Link>
        </div>
      )}
      <Checkbox isChecked={isChecked} onChange={handleCheckbox}>
        {data.answer}
        {!data.isRequired ? (
          <span className="text-smooth ml5">
            (ou votre construction n'est pas concernée)
          </span>
        ) : (
          ""
        )}
      </Checkbox>
    </div>
  );
};

export default Reminder;
