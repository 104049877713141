import React, { useEffect, useState } from "react";
import { useFetchAuth } from "../../redux/api/auth/useFetchAuth";
import { useNavigate } from "react-router-dom";
import { DispatchMenu } from "../../redux/dispatch/dispatchMenu";

const users = [
  { name: "Luc", username: "luc.payen@live.com" },
  { name: "Laure", username: "laureda69@free.fr" },
  { name: "Virginie", username: "virginiefournel@orange.fr" },
  { name: "Sylvain", username: "sylvain.barresi@hotmail.fr" },
];

const Auth = () => {
  const navigate = useNavigate();
  const { getApiBearer } = useFetchAuth();
  const { setActiveMenu } = DispatchMenu();
  const defaultUsername = users[0].username;
  const [username, setUsername] = useState(defaultUsername);
  const [password, setPassword] = useState("API@client$2023");

  const handleLogin = async () => {
    const isLogin = await getApiBearer(username, password);
    if (isLogin) {
      navigate("/");
    }
  };

  useEffect(() => {
    setActiveMenu("");
  }, [])

  return (
    <div id="page-container" className="side-trans-enabled">
      <main id="main-container" className="p0">
        <div
          className="d-flex align-items-center"
          style={{ minHeight: "80vh" }}
        >
          <div className="w-100 content content-full bg-white pt40 pb40" style={{boxShadow: '0 0px 10px rgba(0, 0, 0, .1)'}}>
            <div className="row justify-content-center">
              <div className="col-12 col-md-6 col-lg-6" style={{maxWidth: 400}}>
                <div className="text-center">
                  <div className="mb10">
                    <img src="/img/sfmi.png" height="60" alt="Logo SFMI" />
                  </div>
                  <div className="bold fs20">. Espace Client .</div>
                </div>
                <div className="font-w400 text-muted text-center mt20 mb20">
                  Merci de vous identifier
                </div>
                <div className="mb20">
                  <div style={{ fontWeight: "bold", marginBottom: 5 }}>
                    Identifiant
                  </div>

                  <div className="form-group">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text"
                        >
                          <i className="si si-user"></i>
                        </span>
                      </div>
                      <input
                        type="email"
                        value={username}
                        className="form-control"
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Votre adresse email"
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <div style={{ fontWeight: "bold", marginBottom: 5 }}>
                    Mot de passe
                  </div>
                  <div className="form-group">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text"
                        >
                          <i className="si si-lock"></i>
                        </span>
                      </div>
                      <input
                        type="password"
                        value={password}
                        className="form-control"
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Votre mot de passe"
                      />
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  className="form-control btn btn-danger"
                  style={{
                    display: "inline-block",
                    width: "100%",
                    marginTop: 20,
                  }}
                  onClick={handleLogin}
                >
                  Se connecter
                </button>
                <div style={{ marginTop: 20 }}>
                  {users.map((u, index) => {
                    return (
                      <button
                        key={index}
                        className="form-control"
                        style={{
                          width: "100%",
                          marginBottom: 5,
                          fontSize: 13,
                        }}
                        onClick={() => setUsername(u.username)}
                      >
                        {u.username}
                      </button>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Auth;
