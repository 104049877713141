
import Breadcrumb from "../../Common/Breadcrumb";
import ImagePage from "../Common/ImagePage";
import Menu from "../Common/Menu";
import Information from "../../Common/Information";
import ChooseProject from "../../Common/ChooseProject";
import Partners from "../../Common/Partners";
import BlockRight from "../Common/BlockRight";
import "../../../constant/style/project/calendar.css";
import EventTypes from "../Calendar/EventTypes";
import { MENU_PROJECT } from "../../../constant/url/UrlProject";

const Calendar = () => {
  //console.log('====================================');
  //console.log('Calendar()');

  return (
    <>
      <Breadcrumb
        icon="briefcase"
        title="Un projet dans les règles de l'art"
        links={[
          { name: "Mon projet", url: MENU_PROJECT.project },
          { name: "Mon calendrier" },
        ]}
      />
      <ImagePage url="calendrier" />
      <Menu />
      <div className="row">
        <div className="col col-12 col-xl-9">
          <Information />
          <ChooseProject />
          <div className="block hoverShadow">
            <div className="block-header">
              <h3 className="block-title ">Les grandes étapes</h3>
            </div>
            <div className="block-content">
              <EventTypes />
            </div>
          </div>
          <Partners />
        </div>
        <div className="col col-12 col-xl-3">
          <BlockRight />
        </div>
      </div>
    </>
  );
};

export default Calendar;
