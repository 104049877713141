import { useEffect } from "react";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useFetchPartner } from "../../redux/api/partner/useFetchPartner";
import { getPartnersSelector } from "../../redux/selectors";

import Partner from "./Partner";

const Partners = () => {
  const { getPartners } = useFetchPartner();
  const partners = useSelector(getPartnersSelector);

  useEffect(() => {
    if (!partners.length) {
      getPartners();
    }
  }, []);

  return (
    <div className="block hoverShadow">
      <div className="block-header">
        <h3 className="block-title">Nos partenaires</h3>
      </div>
      <div className="block-content">
        <div className="row d-flex justify-content-center">
          {partners &&
            partners.map((partner) => {
              return <Partner data={partner} key={partner.token} />;
            })}
        </div>
      </div>
    </div>
  );
};

export default Partners;
