import React from "react";
import DATE from "../../../constant/date";

export default function Event({ data, isLast }) {
  const date = data.event ? DATE.getDateFr(data.event.date.date) : "";

  return (
    <div
      style={{
        display:'flex',
        flexDirection: "row",
        width: "100%",
        position: "relative",
      }}
    >
      {!isLast && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 113,
            width: 4,
            height: "100%",
            backgroundColor: "#000",
          }}
        ></div>
      )}
      <div
        style={{
          width: 90,
          height: 30,
          display: 'flex',
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          marginRight: 10,
          borderRadius: 5,
          backgroundColor: !date ? "rgba(0, 0, 0, 0)" : "#eee",
        }}
      >
        <div style={{ fontSize: 12 }}>{date}</div>
      </div>
      <div
        style={{
          width: 30,
          height: 30,
          backgroundColor: "#fff",
          borderRadius: 30,
          borderWidth: 2,
          borderStyle: 'solid',
          display: 'flex',
          justifyContent: "center",
          alignItems: "center",
          borderColor: date ? "#14adc4" : "#da2128",
          zIndex: 100
        }}
      >
        {date && <i className="si si-like bold"></i>}
      </div>
      <div
        style={{
          marginLeft: 10,
          flex: 1,
          marginBottom: !isLast ? 10 : 0,
        }}
      >
        <div
          style={
            {
              minHeight: 60,
              backgroundColor: "#f3f3f3",
              borderRadius: 5,
              padding: 8,
              color: date ? "#14adc4" : "",
              fontWeight: date ? "bold" : ""
            }}
        >
          {data.name}
        </div>
      </div>
    </div>
  );
}
