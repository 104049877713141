import React from "react";
import Menu from "../Common/Menu";
import ImagePage from "../Common/ImagePage";
import ChooseProject from "../../Common/ChooseProject";
import Information from "../../Common/Information";
import BlockRight from "../Common/BlockRight";
import Partners from "../../Common/Partners";
import Categories from "../Document/Categories";
import Breadcrumb from "../../Common/Breadcrumb";
import { MENU_PROJECT } from "../../../constant/url/UrlProject";

const Documents = () => {
  //console.log("====================================");
  //console.log("Document()");

  return (
    <>
      <Breadcrumb
        icon="briefcase"
        title="Un projet dans les règles de l'art"
        links={[
          { name: "Mon projet", url: MENU_PROJECT.project },
          { name: "Mes documents" },
        ]}
      />
      <ImagePage url="document" />
      <Menu />
      <div className="row">
        <div className="col col-12 col-xl-9">
          <Information />
          <ChooseProject />
          <Categories />
          <Partners />
        </div>
        <div className="col col-12 col-xl-3">
          <BlockRight />
        </div>
      </div>
    </>
  );
};

export default Documents;
