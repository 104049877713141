import { Link } from "react-router-dom";
import Breadcrumb from "./Common/Breadcrumb";

const Error = () => {
  return (
    <>
      <Breadcrumb
        icon="info"
        title="Plus qu'un Groupe, une Entité"
        links={[{ name: "SFMI" }]}
      />
      <div className="block hoverShadow">
        <div className="block-content pt100 pb100">
          <div style={{ textAlign: "center" }}>
            <div style={{ fontSize: 40, marginBottom:10 }}>Oups</div>
            <div style={{marginBottom: 10}}>Nous ne parvenons pas à trouver la page.</div>
            <Link to="/">Retour à l'accueil</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Error;
