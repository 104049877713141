import { useSelector } from "react-redux";
import { getPersonsSelector, getUserSelector } from "../../redux/selectors";
import Person from "./Person";
import { useFetchAuth } from "../../redux/api/auth/useFetchAuth";
import { useState } from "react";

const Users = () => {
  const persons = useSelector(getPersonsSelector);
  const user = useSelector(getUserSelector);
  const [datas, setDatas] = useState({
    password: "",
    confirmation: "",
  });
  const [error, setError] = useState("");
  const { updatePassword } = useFetchAuth();

  const handleChange = (text, type) => {
    setDatas((prev) => ({ ...prev, [type]: text }));
  };

  const onSubmit = () => {
    if ("" === datas.password) {
      setError("Le mot de passe ne peut pas être vide.");
      return;
    }

    if (datas.password !== datas.confirmation) {
      setError("Le mot de passe et sa confirmation ne correspondent pas.");
      return;
    }

    setError("");

    setDatas({ ...datas, password: "luc123" });
    updatePassword(datas.password);
  };

  return (
    <div className="row">
      {persons.map((person) => {
        return <Person data={person} key={person.token} />;
      })}
      <div className="col-12">
        <table className="table table-bordered table-striped mb40">
          <thead>
            <tr>
              <th className="bg-9 color-f" colSpan={2}>
                Mes identifiants de connexion
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th className="w1 text-nowrap pr40">Identifiant de connexion</th>
              <td>{user.email}</td>
            </tr>
            <tr>
              <th>Mot de passe</th>
              <td>
                <input
                  type="password"
                  className={`form-control ${error ? "border-danger" : ""}`}
                  value={datas.password}
                  onChange={(e) => handleChange(e.target.value, "password")}
                  style={{ borderColor: error ? "#da2128" : "" }}
                />
                {error && <div className="text-danger mt5">{error}</div>}
              </td>
            </tr>
            <tr>
              <th className="w1 text-nowrap pr40">Confirmation mot de passe</th>
              <td>
                <input
                  type="password"
                  className={`form-control ${error ? "border-danger" : ""}`}
                  value={datas.confirmation}
                  onChange={(e) => handleChange(e.target.value, "confirmation")}
                />
              </td>
            </tr>
            <tr>
              <th className="w1 text-nowrap"></th>
              <td>
                <button className="btn btn-success btn-md" onClick={onSubmit}>
                  Enregistrer
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Users;
