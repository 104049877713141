import { useEffect, useState } from "react";
import { useFetchReview } from "../../redux/api/review/useFetchReview";
import Breadcrumb from "../Common/Breadcrumb";
import Tile from "./Tile";
import Paginator from "./Paginator";
import { useSelector } from "react-redux";
import { getReviewsSelector } from "../../redux/selectors";

const Review = () => {
  //console.log("====================================");
  //console.log("Review()");
  const { getReviews } = useFetchReview();
  // const [reviews, setReviews] = useState([]);
  const reviews = useSelector(getReviewsSelector);
  const [reviewsPaged, setReviewsPaged] = useState([]);
  const [nbPages, setNbPages] = useState(0);
  const [nbItems, setNbItems] = useState(0);
  const [numPage, setNumPage] = useState(0);
  const [active, setActive] = useState(0);
  const nbByPages = 9;

  useEffect(() => {
    if (!reviews.length) {
      getReviews();
    } else {
      const nb = reviews.length;
      setNbItems(nb);
      setNbPages(Math.ceil(nb / nbByPages));
      getReviewsPaged(0);
    }
  }, [reviews]);

  const getReviewsPaged = (numPage) => {
    setNumPage(numPage);
    setActive(numPage);
    const rev = reviews.slice(
      numPage * nbByPages,
      numPage * nbByPages + nbByPages
    );
    setReviewsPaged(rev);
  };

  return (
    <>
      <Breadcrumb
        icon="book-open"
        title="Ils nous ont laissé un commentaire"
        links={[{ name: "Livre d'or" }]}
      />
      <div className="paginator">
        <Paginator
          nbItems={nbItems}
          nbPages={nbPages}
          numPage={numPage}
          onClick={getReviewsPaged}
          active={active}
        />
      </div>
      {/* SI import Tile from "./Tile"; */}
      {/* <div className="mb30 row" style={{ display: "flex" }}> */}
      <div className="block-review mb30">
        {reviewsPaged ? (
          reviewsPaged.map((review, index) => {
            return <Tile data={review} key={index} index={index} />;
          })
        ) : (
          <div className="mb30">En cours de chargement</div>
        )}
      </div>
      <Paginator
        nbItems={nbItems}
        nbPages={nbPages}
        numPage={numPage}
        onClick={getReviewsPaged}
        active={active}
      />
    </>
  );
};

export default Review;
