import { useDispatch } from "react-redux"
import { setActiveMenuAction } from "../actions"

export const DispatchMenu = () => {
    const dispatch = useDispatch()

    const setActiveMenu = (text) => {
        dispatch(setActiveMenuAction(text));
    }

    return {
        setActiveMenu
    }
}