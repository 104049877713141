import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useFetchNews } from "../../redux/api/news/useFetchNews";
import Breadcrumb from "../Common/Breadcrumb";
import TEXT from "../../constant/text";

const NewsDetail = () => {
  const { token } = useParams();
  const { getApiNewsDetail } = useFetchNews();
  const [detail, setDetail] = useState(null);

  useEffect(() => {
    const getInfo = async () => {
      const data = await getApiNewsDetail(token);
      if (data) {
        setDetail(data);
      }
    };
    getInfo();
  }, []);

  return (
    <div className="row">
      <div className="col">
        {/* { col-lg-6 offset-lg-3} */}
        {detail ? (
          <>
            <Breadcrumb
              icon="tag"
              title="Des idées tendance !"
              links={[
                { name: "Toutes nos actualités", url: "/actualites" },
                { name: TEXT.intro(detail.name, 40) },
              ]}
            />
            <div className="block hoverShadow">
              <div className="block-content">
                <h1>{detail.name}</h1>
                <img
                  src={detail.photo}
                  alt={detail.name}
                  style={{ maxWidth: "100%" }}
                />
                {detail.blocks.map((block, index) => {
                  return (
                    <Block
                      data={block}
                      key={block.id}
                      index={index}
                      nb={detail.blocks.length}
                    />
                  );
                })}
              </div>
            </div>
          </>
        ) : (
          <div>Merci de patienter quelques instants</div>
        )}
      </div>
    </div>
  );
};

export default NewsDetail;

const Block = ({ data, index, nb }) => {
  return (
    <data style={{ marginBottom: index !== nb - 1 ? 10 : 0 }}>
      {data.typeName === "Titre" && <Title text={data.text} isTitle={true} />}
      {data.typeName === "Texte" && (
        <>
          <div style={{ fontSize: 14, marginTop: 20, marginBottom: 20 }}>
            {data.text}
          </div>
          <div className="clear"></div>
        </>
      )}
      {data.typeName === "Image" && data.photo && (
        <div>
          <img src={data.photo} alt={index} style={{ maxWidth: "100%" }} />
        </div>
      )}
      {data.typeName === "Lien" && (
        <>
          <div className="clear"></div>
          <Link
            to={data.text}
            style={{ marginTop: 20, marginBottom: 20, display: "block" }}
          >
            {data.text}
          </Link>
        </>
      )}
    </data>
  );
};

const Title = ({ text }) => {
  return (
    <div
      style={{
        fontWeight: "bold",
        fontSize: 24,
        marginBottom: 20,
        // marginTop: 20,
      }}
    >
      {text}
    </div>
  );
};
