const Box = ({
  title = "",
  isHeader = true,
  headerClass = "",
  headerTitleClass = "",
  headerStyle = {},
  contentStyle = {},
  children,
}) => {
  return (
    <div className="block block-shadow">
      {isHeader && (
        <div className={`block-header ${headerClass}`} style={headerStyle}>
          <h3 className={`block-title ${headerTitleClass}`}>{title}</h3>
        </div>
      )}
      <div className="block-content" style={contentStyle}>
        {children}
      </div>
    </div>
  );
};

export default Box;
