function price(number) {
  return new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
  }).format(number);
}

const doExport = {
  price,
}
  
export default doExport;