import Breadcrumb from "./Common/Breadcrumb";

const Legacy = () => {
  return (
    <>
      <Breadcrumb icon="home" title="Mentions légales" links={[{name: 'Mentions légales'}]} />
      <div className="container" id="container-content">
        <div className="row">
          <div className="col col-12">
            <div className="block hoverShadow">
              <div className="block-content">
                <div className="bold mb20 text-uppercase">
                  1. Présentation du site
                </div>
                <div>
                  En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004
                  pour la confiance dans l'économie numérique, il est précisé
                  aux utilisateurs du site https://client.sfmi-construction.fr/
                  l'identité des différents intervenants dans le cadre de sa
                  réalisation et de son suivi :
                </div>
                <div className="bold mb10 mt20 text-uppercase">
                  1.1 Propriétaire
                </div>
                <div>
                  <div className="bold">SAS SFMI</div>
                  RCS Romans-sur-Isère 350 805 396
                  <br />
                  <br />
                  19 Cours Borodine
                  <br />
                  26000 Valence
                  <br />
                  Tél : 04.75.86.11.48
                  <br />
                  Fax : 04.75.43.51.12
                  <br />
                  contact@sfmi-construction.fr
                </div>
                <div className="bold mb10 mt20 text-uppercase">
                  1.2 Maîtrise d'oeuvre, création graphique et développement
                  technique
                </div>
                <div>
                  <div className="bold">SAS SFMI</div>
                  19 Cours Borodine
                  <br />
                  26000 Valence
                  <br />
                  Tél : 04.75.86.11.48
                  <br />
                  Fax : 04.75.43.51.12
                  <br />
                  contact@sfmi-construction.fr
                  <br />
                  <br />
                  Responsable publication : SAS SFMI - contactweb@grpta.fr
                </div>
                <div className="bold mb10 mt20 text-uppercase">
                  1.3 Hébergeur
                </div>
                <div>
                  <div className="bold">OVH</div>
                  2 rue Kellermann
                  <br />
                  59100 Roubaix
                  <br />
                  France
                  <br />
                  RCS Roubaix
                  <br />
                  Tourcoing 424 761 419 00045
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col col-12">
            <div className="block hoverShadow">
              <div className="block-content">
                <div className="bold mb20 text-uppercase">
                  2. Conditions générales d'utilisation du site et des services
                  proposés
                </div>
                <div>
                  Pour plus de facilité https://client.sfmi-construction.fr/
                  sera nommé Espace Client. L'utilisation de l'Espace Client
                  implique l'acceptation pleine et entière des conditions
                  générales d'utilisation ci-après décrites. Ces conditions
                  d'utilisation sont susceptibles d'être modifiées ou complétées
                  à tout moment, les utilisateurs de l'Espace Client sont donc
                  invités à les consulter de manière régulière.
                  <br />
                  Ce site est normalement accessible à tout moment aux
                  utilisateurs. Une interruption pour raison de maintenance
                  technique peut être toutefois décidée par SFMI, qui
                  s'efforcera alors de communiquer préalablement aux
                  utilisateurs les dates et heures de l'intervention. De la même
                  façon, les mentions légales peuvent être modifiées à tout
                  moment: elles s'imposent néanmoins à l'utilisateur qui est
                  invité à s'y référer le plus souvent possible afin d'en
                  prendre connaissance.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col col-12">
            <div className="block hoverShadow">
              <div className="block-content">
                <div className="bold mb20 text-uppercase">
                  3. Description des services fournis
                </div>
                <div>
                  L'Espace Client est un espace réservé au client de SFMI et
                  s'efforce de fournir des informations aussi précises que
                  possible. Toutefois, il ne pourra être tenu responsable des
                  omissions, des inexactitudes et des carences dans la mise à
                  jour, qu'elles soient de son fait ou du fait des tiers
                  partenaires qui lui fournissent ces informations. Tous les
                  informations indiquées sur l'Espace Client sont données à
                  titre indicatif, et sont susceptibles d'évoluer. Par ailleurs,
                  les renseignements figurant sur l'Espace Client ne sont pas
                  exhaustifs. Ils sont donnés sous réserve de modifications
                  ayant été apportées depuis leur mise en ligne.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col col-12">
            <div className="block hoverShadow">
              <div className="block-content">
                <div className="bold mb20 text-uppercase">
                  4. Limitations des responsabilités
                </div>
                <div>
                  Le site Internet ne pourra être tenu responsable de dommages
                  matériels liés à l'utilisation du site. De plus, l'utilisateur
                  du site s'engage à accéder au site en utilisant un matériel
                  récent, ne contenant pas de virus et avec un navigateur de
                  dernière génération mis-à-jour.
                  <br />
                  SFMI ne pourra également être tenue responsable des dommages
                  indirects (tels par exemple qu'une perte de marché ou perte
                  d'une chance) consécutifs à l'utilisation de l'Espace Client.
                  <br />
                  Des espaces interactifs (possibilité de poser des questions
                  dans l'espace contact) sont à la disposition des utilisateurs.
                  SFMI se réserve le droit de supprimer, sans mise en demeure
                  préalable, tout contenu déposé dans cet espace qui
                  contreviendrait à la législation applicable en France, en
                  particulier aux dispositions relatives à la protection des
                  données. Le cas échéant, SFMI se réserve également la
                  possibilité de mettre en cause la responsabilité civile et/ou
                  pénale de l'utilisateur, notamment en cas de message à
                  caractère raciste, injurieux, diffamant, ou pornographique,
                  quel que soit le support utilisé (texte, photographie…).
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col col-12">
            <div className="block hoverShadow">
              <div className="block-content">
                <div className="bold mb20 text-uppercase">
                  5. Gestion des données personnelles
                </div>
                <div>
                  En France, les données personnelles sont notamment protégées
                  par la loi n° 78-87 du 6 janvier 1978, la loi n°2004-575 du 21
                  juin 2004 pour la confiance dans l'économie numérique, la loi
                  n° 2004-801 du 6 août 2004, l'article L. 226-13 du Code pénal,
                  les dispositions de la loi du 1er juillet 1998 transposant la
                  directive 96/9 du 11 mars 1996 relative à la protection
                  juridique des bases de données et le règlement général
                  européen pour la protection des données (RGPD).
                  <br />
                  <br />
                  L'utilisateur est seul responsable des données qu'il
                  communique. Afin que vous puissiez naviguer sur notre site en
                  toute tranquillité, en tant que responsable du traitement,
                  nous vous exposons comment nous collectons, traitons et
                  utilisons vos données personnelles, pour vous apporter au
                  quotidien de nouveaux services dans le respect de vos droits.
                  Nous protégeons votre vie privée en assurant la protection, la
                  confidentialité, la non altération, la disponibilité et la
                  sécurité des données personnelles que vous nous confiez sur
                  l'ensemble de nos canaux de communication.
                  <br />
                  <br />
                  La collecte et le traitement des informations personnelles sur
                  internet se font dans le respect des droits fondamentaux des
                  personnes. L'utilisateur fournit ces informations en toute
                  connaissance de cause, notamment lorsqu'il procède par
                  lui-même à leur saisie.
                  <br />
                  <br />
                  Sécurité des données :<br />
                  Aucune information personnelle de l'utilisateur du site n'est
                  publiée à l'insu de l'utilisateur, échangée, transférée, cédée
                  ou vendue sur un support quelconque à des tiers. Seule
                  l'hypothèse du rachat de SFMI et de ses droits permettrait la
                  transmission des dites informations à l'éventuel acquéreur qui
                  serait à son tour tenu de la même obligation de conservation
                  et de modification des données vis à vis de l'utilisateur de
                  l'Espace Client.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col col-12">
            <div className="block hoverShadow">
              <div className="block-content">
                <div className="bold mb20 text-uppercase">
                  6. Collecte des données personnelles
                </div>
                <div className="bold mb10 mt20 text-uppercase">
                  1.1 Collecte des informations au travers des formulaires
                </div>
                <div>
                  Les informations collectées sur l'Espace Client sont destinées
                  à SFMI. Elles font l'objet d'un traitement informatique ayant
                  pour finalité le traitement de votre demande, la bonne avancée
                  de nos relations commerciales et notre gestion commerciale en
                  particulier :
                  <ul>
                    <li>La réponse aux demandes formulées sur le site</li>
                    <li>
                      L'envoi d'informations commerciales par mail lorsque vous
                      avez donné votre accord ou lorsque la législation en
                      vigueur l'autorise
                    </li>
                    <li>
                      L'établissement de statistiques générales sur la
                      consultation du site afin d'améliorer les prestations que
                      nous offrons sur ce site.
                    </li>
                  </ul>
                  Tout utilisateur du site dispose d'un droit d'accès,
                  modification, de rectification ou de suppression aux données
                  personnelles le concernant. Il peut exercer ces droits en nous
                  contactant aux coordonnées indiquées en haut de page. Pour
                  faciliter l'exercice de ces droits, les utilisateurs du site
                  peuvent se désinscrire en cliquant sur les liens hypertextes
                  de désinscription présents sur les mails adressés
                </div>
                <div className="bold mb10 mt20 text-uppercase">
                  6.2 Sécurité des échanges
                </div>
                <div>
                  Nous nous engageons à prendre toutes les mesures juridiques et
                  techniques pour sécuriser les échanges. Nous nous engageons à
                  une obligation de moyen pour bloquer les détournements de
                  données, et à respecter les réglementations applicables.
                  Néanmoins, les utilisateurs du site connaissent les
                  particularités techniques du réseau internet et les risques
                  afférents. Par conséquent, nous ne pouvons garantir à nos
                  utilisateurs que les données échangées via les services
                  proposés par le site ne soient pas récoltées de façon
                  frauduleuse par des tiers.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col col-12">
            <div className="block hoverShadow">
              <div className="block-content">
                <div className="bold mb20 text-uppercase">
                  7. Liens hypertextes
                </div>
                <div>
                  L'Espace Client peut contenir un certain nombre de liens
                  hypertextes vers d'autres sites, mis en place avec
                  l'autorisation de SFMI. Cependant, SFMI n'a pas la possibilité
                  de vérifier le contenu des sites ainsi visités, et n'assumera
                  en conséquence aucune responsabilité de ce fait.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col col-12">
            <div className="block hoverShadow">
              <div className="block-content">
                <div className="bold mb20 text-uppercase">
                  8. Crédits photos
                </div>
                <div>Fotolia</div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col col-12">
            <div className="block hoverShadow">
              <div className="block-content">
                <div className="bold mb20 text-uppercase">
                  9. Droit applicable et attribution de juridiction
                </div>
                <div>
                  Tout litige en relation avec l'utilisation du site
                  https://client.sfmi-construction.fr/ est soumis au droit
                  français. Il est fait attribution exclusive de juridiction aux
                  tribunaux compétents de Paris.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col col-12">
            <div className="block hoverShadow">
              <div className="block-content">
                <div className="bold mb20 text-uppercase">
                  10. Les principales lois concernées
                </div>
                <div>
                  Loi n° 78-17 du 6 janvier 1978, notamment modifiée par la loi
                  n° 2004-801 du 6 août 2004 relative à l'informatique, aux
                  fichiers et aux libertés.
                  <br />
                  Article L. 226-13 du Code pénal et la Directive Européenne du
                  24 octobre 1995
                  <br />
                  Loi du 1er juillet 1998 transposant la directive 96/9 du 11
                  mars 1996
                  <br />
                  Loi n° 2004-575 du 21 juin 2004 pour la confiance dans
                  l'économie numérique
                  <br />
                  Loi n° 2004-801 du 6 août 2004
                  <br />
                  Article L. 226-13 du Code pénal et la Directive Européenne du
                  24 octobre 1995
                  <br />+ Règlement Général de Protection des Données (règlement
                  2016/679 du 27 avril 2016) entré en vigueur le 24 mai 2016
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col col-12">
            <div className="block hoverShadow">
              <div className="block-content">
                <div className="bold mb20 text-uppercase">11. Médiation</div>
                <div>
                  En cas de litige non résolu, après réclamation formulée auprès
                  du Constructeur par lettre recommandée avec accusé de
                  réception, le Maître d'Ouvrage dispose de la faculté de saisir
                  MEDIMMOCONSO, médiateur de la consommation, conformément à
                  l'article L 612-1 du Code de la consommation. Cette saisine
                  peut être effectuée au choix du Maitre d'Ouvrage soit :
                  <ul>
                    <li>
                      en ligne :
                      https://medimmoconso.fr/adresser-une-reclamation
                    </li>
                    <li>
                      par courrier : Association MEDIMMOCONSO, 1 Allée du Parc
                      de Mesemena – Bât. A - CS 25222 - 44505 LA BAULE CEDEX
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col col-12">
            <div className="block hoverShadow">
              <div className="block-content">
                <div className="bold mb20 text-uppercase">
                  12. Propriété intellectuelle et contrefaçons
                </div>
                <div>
                  SFMI est propriétaire des droits de propriété intellectuelle
                  ou détient les droits d'usage sur tous les éléments
                  accessibles sur le site, notamment les textes, images,
                  graphismes, logo, icônes, sons, logiciels.
                  <br />
                  Toute reproduction, représentation, modification, publication,
                  adaptation de tout ou partie des éléments du site, quel que
                  soit le moyen ou le procédé utilisé, est interdite, sauf
                  autorisation écrite préalable de : SFMI. Le contenu est
                  disponible pour un usage privé et non collectif. Le code de la
                  propriété intellectuelle n'autorisant, aux termes de l'article
                  L. 122-5.2° et 3°a, d'une part, que les "copies ou
                  reproductions strictement réservées à l'usage privé du copiste
                  et non destinées à une utilisation collective" et, d'autre
                  part, que les analyses et les courtes citations dans un but
                  d'exemple et d'illustration, "toute représentation ou
                  reproduction intégrale ou partielle faite sans le consentement
                  de l'auteur ou de ses ayants droit ou ayants cause est
                  illicite" (art. L. 122-4). Cette représentation ou
                  reproduction, par quelque procédé que ce soit constituerait
                  donc une contrefaçon sanctionnée par les articles L. 335-2 et
                  suivants du code de la propriété intellectuelle.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col col-12">
            <div className="block hoverShadow">
              <div className="block-content">
                <div className="bold mb20 text-uppercase">
                  13. Plans, modèles de maisons
                </div>
                <div>
                  SFMI conserve en toute hypothèse ses droits et notamment
                  l'entière propriété des plans et représentations en
                  perspectives de ses maisons, avec l'exclusivité des droits de
                  reproductions conformément aux lois du 11 mars 1957 et du 3
                  juillet 1985.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Legacy;
