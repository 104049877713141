import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getActiveMenuSelector } from "../redux/selectors";
import { menus } from "../constant/Menu";

const Menu = ({ handleMenu, children }) => {
  const activeMenu = useSelector(getActiveMenuSelector);

  return (
    <>
      <ul>
        {children}
        {menus.map((menu, index) => {
          return (
            <li
              className={-1 !== activeMenu.indexOf(menu.link) ? "active" : ""}
              key={index}
            >
              <Link to={menu.link} onClick={() => handleMenu(menu.link)}>
                {menu.name}
              </Link>
            </li>
          );
        })}
        <li>
          <Link to="/deconnexion">
            <i className="si si-lock fs14"></i>
          </Link>
        </li>
      </ul>
    </>
  );
};

export default Menu;
