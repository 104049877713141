import React from "react";
import {
  getProjectSelectedSelector,
  getProjectsSelector,
} from "../../redux/selectors";
import { useSelector } from "react-redux";
import { DispatchProject } from "../../redux/dispatch/dispatchProject";
import NUMBER from "../../constant/number";

const ChooseProject = () => {
  //console.log("ChooseProject()");
  const projects = useSelector(getProjectsSelector);
  const { setProjectSelected } = DispatchProject();
  const actualProject = useSelector(getProjectSelectedSelector);

  const handleProject = (project) => {
    setProjectSelected(project);
  };

  if (projects.length <= 1) {
    return "";
  }
  return (
    <div className="block hoverShadow">
      <div className="block-header pr10">
        <h3 className="block-title ">Liste de vos projets</h3>
      </div>
      <div className="block-content pt10 pb10">
        <div style={{ display: "flex", flexDirection: "row" }}>
          {projects.map((project) => {
            const isActive = actualProject.token === project.token;
            return (
              <div
                onClick={() => {
                  handleProject(project);
                }}
                key={project.token}
                className={`btn btn-square btn-lg btn-light p20 mr20 mb10 text-center bold relative ${
                  isActive ? "active" : ""
                }`}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  {isActive && (
                    <i className="fa fa-check" style={{ marginRight: 10 }}></i>
                  )}
                  <div>
                    <div style={{ fontSize: 10 }}>{project.contractCode}</div>
                    <div style={{ fontSize: 10 }}>
                      {NUMBER.price(project.ccmiTtc)}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default React.memo(ChooseProject);
