export const menus = [
  {
    name: "Qui sommes-nous ?",
    link: "/groupe",
    icon: "magnifier",
  },
  {
    name: "Les prestations",
    link: "/groupe/prestations",
    icon: "list",
  },
  {
    name: "Les partenaires",
    link: "/groupe/partenaires",
    icon: "share",
  },
  {
    name: "Les règlementations",
    link: "/groupe/reglementation",
    icon: "note",
  },
];
