import React from "react";
import { Link } from "react-router-dom";

const Question = () => {
    return (
        <div className="p10">
        <div className="text-center">
          <div className="mb20">
            <i className="si si-bubbles fs40 pt5"></i>
          </div>
          <div className="">
            <span className="bold">Vous avez une question ?</span>
            <br />
            Rendez-vous dans votre espace.
          </div>
          <div className="mt20">
            <Link
              to="/tchat"
              className="btn btn-square btn-sm btn-outline-secondary"
            >
              Espace Discussion
            </Link>
          </div>
        </div>
      </div>
    )
    }
    
    export default React.memo(Question);