import Docs from "./Docs";

const Types = ({ data }) => {
  return (
    <>
      {data.map((type) => (
        <Docs data={type.docs} key={type.token} />
      ))}
    </>
  );
};

export default Types;
