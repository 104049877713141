import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  getProjectSelectedSelector,
  getRemindersCheckedSelector,
  getRemindersSelector,
  getRemindersTotalSelector,
} from "../../../redux/selectors";
import { useFetchReminder } from "../../../redux/api/reminder/useFetchReminder";
import Category from "./Category";
import Box from "../../Common/Box";
import TEXT from "../../../constant/text";

const Categories = () => {
  const { getApiReminders } = useFetchReminder();
  const project = useSelector(getProjectSelectedSelector);
  const reminders = useSelector(getRemindersSelector);
  const nbRemindersSelected = useSelector(getRemindersCheckedSelector);
  const nbReminders = useSelector(getRemindersTotalSelector);

  useEffect(() => {
    getApiReminders();
  }, [project]);

  return (
    <>
      <Box
        isHeader={false}
        contentStyle={{
          borderLeftWidth: 3,
          borderLeftStyle: "solid",
          borderLeftColor: "#FFCF15",
        }}
      >
        <div className="row">
          <div className="d-none d-lg-block col-lg-2 mb10 pt30 text-center">
            <i className="si si-bulb fs80"></i>
          </div>
          <div className="col-12 col-lg-10 pl20">
            <div className="fs25 text-primary">Avez-vous pensé à tout ?</div>
            <div>
              Suite à votre rendez-vous de mise au point technique, voici les
              démarches que vous devrez effectuer pour la suite du déroulement
              de votre projet. A chaque démarche effectuée, nous vous invitons à
              la cocher, ce qui vous permettra de suivre en temps réel ce que
              vous avez fait et ce qu'il vous reste à faire. Quelques
              explications vous permettent de mieux comprendre chaque étape pour
              la mener à bien et optimiser ainsi la réalisation de votre projet.
              Nous restons disponibles si vous avez besoin d'informations
              complémentaires.
            </div>
            <div id="reminder-count">
              {nbRemindersSelected === nbReminders ? (
                <div className="mt10 text-success">
                  <i className="si si-like fs34 fl pt5 mr20"></i>
                  <div className="text-uppercase">
                    OUI, vous avez pensé à tout !
                    <br />
                    <span className="fs20 bold">
                      Toutes les cases sont cochées
                    </span>
                  </div>
                </div>
              ) : (
                <div className="mt10 text-danger">
                  <i className="si si-info fs34 fl pt5 mr20"></i>
                  <div className="text-uppercase">
                    Cochez chaque case pour vous assurer de n'avoir rien oublié
                    !
                  </div>
                  {0 === nbRemindersSelected ? (
                    <>
                      <span className="fs20 bold">Aucune case</span> n'est
                      actuellement cochée.
                    </>
                  ) : (
                    <>
                      <span className="fs20 bold">
                        {nbRemindersSelected} sur {nbReminders}
                      </span>{" "}
                      {nbRemindersSelected > 1
                        ? "sont actuellement cochées"
                        : "est actuellement cochée"}
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="clear"></div>
        </div>

        {/* <div className="bold">
              Vous avez actuellement validé {nbRemindersSelected} pense-bêtes
              sur {nbReminders}
            </div> */}
      </Box>
      {reminders.length &&
        reminders.map((category) => {
          return <Category data={category} key={TEXT.random()} />;
        })}
    </>
  );
};

export default Categories;
