import { useSelector } from "react-redux";
import { getProjectSelectedSelector } from "../../../redux/selectors";
import Menu from "../Common/Menu";
import ImagePage from "../Common/ImagePage";
import "../../../constant/style/project/project.css";
import ChooseProject from "../../Common/ChooseProject";
import Person from "../Home/Person";
import Btn from "../Home/Btn";
import Information from "../../Common/Information";
import BlockRight from "../Common/BlockRight";
import Partners from "../../Common/Partners";
import Breadcrumb from "../../Common/Breadcrumb";
import { MENU_PROJECT } from "../../../constant/url/UrlProject";

const Project = () => {
  //console.log("====================================");
  //console.log("Home()");
  const project = useSelector(getProjectSelectedSelector);

  return (
    <>
      <Breadcrumb
        icon="briefcase"
        title="Un projet dans les règles de l'art"
        links={[{ name: "Mon projet" }]}
      />
      <ImagePage url="projet" />
      <Menu />
      <div className="row">
        <div className="col col-12 col-xl-9">
          <Information />
          <ChooseProject />
          <Person />
          <div className="row margin-block">
            <Btn
              name="document"
              nb={project.documents.length}
              url={MENU_PROJECT.document}
              icon="docs"
            />
            <Btn
              name="photo"
              nb={project.documents.length}
              url={MENU_PROJECT.photo}
              icon="picture"
            />
            <Btn
              name="pense-bête"
              nb={project.documents.length}
              url={MENU_PROJECT.reminder}
              icon="bulb"
              suffix="à valider"
            />
          </div>
          <Partners />
        </div>
        <div className="col col-12 col-xl-3">
          <BlockRight />
        </div>
      </div>
    </>
  );
};

export default Project;
