import React from "react";
import Employee from "./Employe";
import News from "./News";
import Question from "./Question";

const BlockRight = () => {
  //console.log('BlockRight()');
  return (
    <div className="block hoverShadow">
      <div className="block-content p10">
        <div className="minh500">
          <Question />
          <hr />
          <Employee />
          <hr />
          <News />
        </div>
      </div>
    </div>
  );
};

export default React.memo(BlockRight);