import {
  GET_BEARER,
  GET_USER,
  GET_VIDEOS,
  GET_SELECTED_VIDEO,
  GET_NEWS,
  GET_SELECTED_NEWS,
  GET_NEWS_CATEGORIES,
  SET_PROJECT_SELECTED,
  GET_EVENTS,
  GET_DOCUMENTS,
  GET_PHOTOS,
  GET_PARTNERS,
  GET_REMINDERS,
  GET_REMINDERS_CHECKED,
  GET_REMINDERS_TOTAL,
  GET_REVIEWS,
  SET_ACTIVE_MENU,
} from "./actionsType";

export const getBearerAction = (data) => ({
  type: GET_BEARER,
  payload: {
    data,
  },
});

export const getUserAction = (data) => ({
  type: GET_USER,
  payload: {
    data,
  },
});

export const getVideosAction = (data) => ({
  type: GET_VIDEOS,
  payload: {
    data,
  },
});

export const getSelectedVideoAction = (data) => ({
  type: GET_SELECTED_VIDEO,
  payload: {
    data,
  },
});

export const getNewsAction = (data) => ({
  type: GET_NEWS,
  payload: {
    data,
  },
});

export const getSelectedNewsAction = (data) => ({
  type: GET_SELECTED_NEWS,
  payload: {
    data,
  },
});

export const getNewsCategoriesAction = (data) => ({
  type: GET_NEWS_CATEGORIES,
  payload: {
    data,
  },
});

export const setProjectSelectedAction = (data) => ({
  type: SET_PROJECT_SELECTED,
  payload: {
    data,
  },
});

export const getEventsAction = (data) => ({
  type: GET_EVENTS,
  payload: {
    data,
  },
});

export const getDocumentsAction = (data) => ({
  type: GET_DOCUMENTS,
  payload: {
    data,
  },
});

export const getPhotosAction = (data) => ({
  type: GET_PHOTOS,
  payload: {
    data,
  },
});

export const getPartnersAction = (data) => ({
  type: GET_PARTNERS,
  payload: {
    data,
  },
});

export const setRemindersAction = (data) => ({
  type: GET_REMINDERS,
  payload: {
    data,
  },
});

export const setRemindersCheckedAction = (data) => ({
  type: GET_REMINDERS_CHECKED,
  payload: {
    data,
  },
});

export const setRemindersTotalAction = (data) => ({
  type: GET_REMINDERS_TOTAL,
  payload: {
    data,
  },
});

export const setReviewsAction = (data) => ({
  type: GET_REVIEWS,
  payload: {
    data,
  },
});

export const setActiveMenuAction = (data) => ({
  type: SET_ACTIVE_MENU,
  payload: {
    data,
  },
});
