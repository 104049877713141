import React from "react";
import { useSelector } from "react-redux";
import {
  getProjectSelectedSelector,
  getUserSelector,
} from "../../redux/selectors";
import TEXT from "../../constant/text";

const formateContractCode = (text) => {
  const pole = text.substr(0, 3);
  const number1 = text.substr(3, 3);
  const letter = text.substr(6, 1);
  const number2 = text.substr(7, 3);
  return `${pole} ${number1} ${letter} ${number2}`;
};

const Information = () => {
  //console.log("Information()");
  const user = useSelector(getUserSelector);
  const project = useSelector(getProjectSelectedSelector);
  return (
    <div className="block hoverShadow">
      <div className="block-content">
        <div className="row">
          <div className="col-12 col-sm-6">
            <span className="bold mr10 text-uppercase">
              <i className="si si-user mr5 bold"></i>
              Référence Client
            </span>
            {TEXT.wordwrap(user.customerCode, 3, " ", true)}
          </div>
          <div className="col-12 col-sm-6">
            <span className="fr text-right pull-right" id="contract-code">
              <span className="bold mr10 text-uppercase">
                <i className="si si-briefcase mr5 bold"></i>
                Code CONTRAT
              </span>
              {formateContractCode(project.contractCode)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Information);
