import Box from "../../Common/Box";
import Reminder from "./Reminder";
import TEXT from '../../../constant/text'

const Category = ({ data }) => {
  return (
    <Box
      title={data.name}
      headerClass="bg-modern"
      headerTitleClass="color-f"
      contentStyle={{
        paddingBottom: 0,
      }}
    >
      {data.reminders.map((reminder) => {
        return <Reminder data={reminder} key={TEXT.random()} />;
      })}
    </Box>
  );
};

export default Category;
