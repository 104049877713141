const Checkbox = ({ isChecked, onChange, color = "#aa45cc", children }) => {
  return (
    <>
      <label className="checkbox-custom-block" onClick={onChange}>
        <div
          className={`checkbox-custom ${isChecked ? "checked" : ""}`}
          style={{
            backgroundColor: isChecked ? color : "#eee",
            borderColor: isChecked ? "rgba(0, 0, 0, .4)" : "#999",
          }}
        >
          {isChecked ? <i className="fa fa-check fs8"></i> : ""}
        </div>
        {children}
      </label>
    </>
  );
};

export default Checkbox;
