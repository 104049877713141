import { SET_ACTIVE_MENU } from "../actions/actionsType";

const defaultState = {
  activeMenu: '',
};

export const menuReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_ACTIVE_MENU:
      return {
        activeMenu: action.payload.data,
      };
    default:
      return state;
  }
};
