export const menus = [
  {
    name: "Une question",
    link: "/tchat",
  },
  {
    name: "Actualités",
    link: "/actualites",
  },
  {
    name: "Le groupe",
    link: "/groupe",
  },
  {
    name: "Livre d'or",
    link: "/livre-or",
  },
  {
    name: "Mon compte",
    link: "/mon-compte",
  },
];
