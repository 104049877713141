import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  getProjectSelectedSelector,
  getPhotosSelector,
} from "../../../redux/selectors";
import { useFetchPhoto } from "../../../redux/api/photo/useFetchPhoto";
import Photo from "./Photo";

const Categories = () => {
  const { getApiPhotos } = useFetchPhoto();
  const project = useSelector(getProjectSelectedSelector);
  const photos = useSelector(getPhotosSelector);

  useEffect(() => {
    getApiPhotos(project);
  }, [project]);

  return (
    <>
      {photos.length ? (
        photos.map((category, index) => (
          <div className="block hoverShadow" key={category.token}>
            <div className="block-header bg-modern">
              <h3 className="block-title fw300 color-f">
                Photos de type <span className="fw700">{category.name}</span>
              </h3>
            </div>
            <div className="block-content">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 10,
                  flexWrap: "wrap",
                }}
              >
                {category.docs.map((doc) => {
                  return <Photo data={doc} key={doc.token} />;
                })}
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="block hoverShadow">
          <div className="block-content">Aucune photo pour le moment.</div>
        </div>
      )}
    </>
  );
};

export default Categories;
