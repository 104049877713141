import {
  GET_BEARER,
  GET_USER,
  SET_PROJECT_SELECTED,
} from "../actions/actionsType";

const defaultState = {
  bearer: null,
  user: null,
  persons: [],
  projects: [],
  projectSelected: null,
};

export const userReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_BEARER:
      return {
        bearer: action.payload.data,
        user: state.user,
        persons: state.persons,
        projects: state.projects,
        projectSelected: state.projectSelected,
      };
    case GET_USER:
      const user = action.payload.data;
      // localStorage.setItem('user', user);
      const activeProject =
        user && user.projects
          ? user.projects[user.projects.length - 1]
          : state.projectSelected;
      return {
        bearer: state.bearer,
        user: user,
        persons: user ? user.persons : [],
        projects: user ? user.projects : [],
        projectSelected: activeProject,
      };
    case SET_PROJECT_SELECTED:
      return {
        bearer: state.bearer,
        user: state.user,
        persons: state.persons,
        projects: state.projects,
        projectSelected: action.payload.data,
      };
    default:
      return state;
  }
};
