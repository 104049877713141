import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getPhotosAction } from "../../actions";
import { API_URL } from "../Url";
import { getBearerSelector } from "../../selectors";
import { useFetchAuth } from "../auth/useFetchAuth";

export const useFetchPhoto = () => {
  const dispatch = useDispatch();
  const bearer = useSelector(getBearerSelector);
  const { logout } = useFetchAuth();

  const getApiPhotos = async (project) => {
    try {
      const response = await axios.get(`${API_URL}/project/${project.token}/photos`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `bearer ${bearer}`,
        },
      });
      dispatch(getPhotosAction(response.data));
      return true;
    } catch (error) {
      // logout();
      return [];
    }
  };

  return {
    getApiPhotos,
  };
};
