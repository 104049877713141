import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  getBearerSelector,
  getProjectSelectedSelector,
} from "../../selectors";
import { API_URL } from "../Url";
import {
  setRemindersAction,
  setRemindersCheckedAction,
  setRemindersTotalAction,
} from "../../actions";

export const useFetchReminder = () => {
  const bearer = useSelector(getBearerSelector);
  const project = useSelector(getProjectSelectedSelector);
  const dispatch = useDispatch();

  const getApiReminders = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/project/${project.token}/reminders`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `bearer ${bearer}`,
          },
        }
      );
      dispatch(setRemindersAction(response.data));
      let nbChecked = 0;
      let nbTotal = 0;
      response.data.map((category) => {
        category.reminders.map((reminder) => {
          nbTotal++;
          if (reminder.isChecked) {
            nbChecked++;
          }
          // return true;
        });
        // return true;
      });
      dispatch(setRemindersTotalAction(nbTotal));
      dispatch(setRemindersCheckedAction(nbChecked));
      return true;
    } catch (e) {
    }
  };

  const setApiReminderChecked = async (reminderId) => {
    try {
      await axios.post(
        `${API_URL}/project/${project.token}/reminders/${reminderId}`,
        {},
        {
          headers: {
            Authorization: `bearer ${bearer}`,
          },
        }
      );
      return getApiReminders();
    } catch (e) {
    }
  };

  return {
    getApiReminders,
    setApiReminderChecked,
  };
};
