import { useEffect, useState } from "react";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useFetchNews } from "../../redux/api/news/useFetchNews";
import { getNewsCategoriesSelector, getNewsSelector } from "../../redux/selectors";
import Tile from "./Tile";
import ARRAY from "../../constant/array";

const Categories = () => {
  const { getApiNewsByCategories } = useFetchNews();
  const newsCategories = useSelector(getNewsCategoriesSelector);
  const news = useSelector(getNewsSelector);
  const [catSelected, setCatSelected] = useState(null);
  const [randomNews, setRandomNews] = useState([]);

  useEffect(() => {
    if (!newsCategories.length) {
      getApiNewsByCategories();
    }
    let shuffle = ARRAY.shuffle(news).slice(0, news.length - 1);
    setRandomNews(shuffle);
  }, [news]);

  return (
    <>
      <div className="block hoverShadow">
        <div className="block-content pb0">
          <ul id="home-category">
            <li className="home-category" onClick={() => setCatSelected(null)}>
              <div
                className={`mr20 mb20 pointer bold ${
                  null === catSelected ? "active text-primary" : ""
                }`}
              >
                Toutes
              </div>
            </li>
            {newsCategories.length &&
              newsCategories.map((cat) => {
                return (
                  <li
                    className="home-category"
                    key={cat.token}
                    onClick={() => setCatSelected(cat)}
                  >
                    <div
                      className={`mr20 mb20 pointer ${
                        catSelected && cat.token === catSelected.token
                          ? "active bold text-primary"
                          : ""
                      }`}
                    >
                      {cat.name}
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
      <Tile category={catSelected} all={randomNews} />
    </>
  );
};

export default Categories;
