const isDev = () => {
  return "localhost" === window.location.hostname;
};

const isProd = () => {
  return !isDev();
};

const doExport = {
  isProd,
  isDev,
};

export default doExport;
