import React, { useEffect, useState } from "react";

const Paginator = ({ nbItems, nbPages, numPage, onClick, active }) => {
  const [btns, setBtns] = useState([]);

  const setClick = (num) => {
    onClick(num);
  };

  useEffect(() => {
    let arr = [];
    for (let i = 0; i < nbPages; i++) {
      const btn = (
        <div
          className={`btn btn-light m2 ${active === i ? "active" : ""}`}
          onClick={() => setClick(i)}
          key={i}
        >
          {i + 1}
        </div>
      );
      arr.push(btn);
    }
    setBtns(arr);
  }, [numPage, nbPages, active]);

  return <div className="mb20 text-center">{btns}</div>;
};

export default React.memo(Paginator);
