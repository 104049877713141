import DATE from "../../constant/date";

const Comment = ({ data, isLast }) => {
  const who = data.employee
    ? `${data.employee.firstname} ${data.employee.lastname}`
    : "Vous";

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
      }}
    >
      <div
        className={`tchat-block-detail ${isLast ? "" : "mb40"} 
        ${data.employee ? "no-owner" : ""}`}
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          alignItems: data.employee ? "flex-end" : "flex-start",
          marginBottom: !isLast ? 30 : 0,
          width: "75%",
        }}
      >
        <div className="tchat-detail-user">
          <span className="bold mr10">{who}</span>
          <span className="tchat-detail-date">
            {DATE.getDateTime(data.date)}
          </span>
        </div>
        <div className="tchat-detail" style={{ width: "60%" }}>
          {data.text.split("\n").map((str, index) => (
            <div key={index}>{str}</div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Comment;
