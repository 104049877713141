export const GET_BEARER = 'GET_BEARER'
export const GET_USER = 'GET_USER'
export const GET_VIDEOS = 'GET_VIDEOS'
export const GET_SELECTED_VIDEO = 'GET_SELECTED_VIDEO'
export const GET_NEWS = 'GET_NEWS'
export const GET_SELECTED_NEWS = 'GET_SELECTED_NEWS'
export const GET_NEWS_CATEGORIES = 'GET_NEWS_CATEGORIES'
export const SET_PROJECT_SELECTED = 'SET_PROJECT_SELECTED'
export const GET_EVENTS = 'GET_EVENTS'
export const GET_DOCUMENTS = 'GET_DOCUMENTS'
export const GET_PHOTOS = 'GET_PHOTOS'
export const GET_PARTNERS = 'GET_PARTNERS'
export const GET_REMINDERS = 'GET_REMINDERS'
export const GET_REMINDERS_CHECKED = 'GET_REMINDERS_CHECKED'
export const GET_REMINDERS_TOTAL = 'GET_REMINDERS_TOTAL'
export const GET_REVIEWS = 'GET_REVIEWS'
export const SET_ACTIVE_MENU = 'SET_ACTIVE_MENU'