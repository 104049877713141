import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getDocumentsAction } from "../../actions";
import { API_URL } from "../Url";
import { getBearerSelector, getProjectSelectedSelector } from "../../selectors";
import { useFetchAuth } from "../auth/useFetchAuth";

export const useFetchDocument = () => {
  const dispatch = useDispatch();
  const bearer = useSelector(getBearerSelector);
  const project = useSelector(getProjectSelectedSelector)
  const { logout } = useFetchAuth();

  const getApiDocuments = async () => {
    try {
      const response = await axios.get(`${API_URL}/project/${project.token}/documents`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `bearer ${bearer}`,
        },
      });
      dispatch(getDocumentsAction(response.data));
      return true;
    } catch (error) {
      return false;
    }
  };

  return {
    getApiDocuments,
  };
};
