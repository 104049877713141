import { GET_PARTNERS } from "../actions/actionsType";

const defaultState = {
  partners: [],
};

export const partnerReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_PARTNERS:
      return {
        partners: action.payload.data,
      };
    default:
      return state;
  }
};
