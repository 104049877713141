import { useEffect } from "react";
import { useFetchAuth } from "../redux/api/auth/useFetchAuth";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const navigate = useNavigate();
  const { logout } = useFetchAuth();
  useEffect(() => {
    logout();
    navigate("/identification")
  });
  return;
};

export default Logout;
