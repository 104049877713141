import {
  GET_REMINDERS,
  GET_REMINDERS_CHECKED,
  GET_REMINDERS_TOTAL,
} from "../actions/actionsType";

const defaultState = {
  reminders: [],
  remindersChecked: 0,
  remindersTotal: 0,
};

export const reminderReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_REMINDERS:
      return {
        reminders: action.payload.data,
        remindersChecked: state.remindersChecked,
        remindersTotal: state.remindersTotal,
      };
    case GET_REMINDERS_CHECKED:
      return {
        reminders: state.reminders,
        remindersChecked: action.payload.data,
        remindersTotal: state.remindersTotal,
      };
    case GET_REMINDERS_TOTAL:
      return {
        reminders: state.reminders,
        remindersChecked: state.remindersChecked,
        remindersTotal: action.payload.data,
      };
    default:
      return state;
  }
};
