import ChooseProject from "../Common/ChooseProject";
import Information from "../Common/Information";
import Breadcrumb from "../Common/Breadcrumb";
import { DispatchMenu } from "../../redux/dispatch/dispatchMenu";
import Tchats from "./Tchats";
import { useEffect } from "react";

const Tchat = () => {
  //console.log('====================================');
  //console.log('Tchat()');
  const {setActiveMenu} = DispatchMenu();
  
  useEffect(() => {    
    setActiveMenu('/tchat');
  }, [])

  return (
    <>
      <Breadcrumb
        icon="bubbles"
        title="Entre vous et nous"
        links={[{ name: "Discussion" }]}
      />
      <Information />
      <ChooseProject />
      <Tchats />
    </>
  );
};

export default Tchat;
