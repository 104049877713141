import { MENU_PROJECT } from "./url/UrlProject";

export const menus = [
  {
    name: "Mon projet",
    link: MENU_PROJECT.project,
    icon: "briefcase",
  },
  {
    name: "Mes documents",
    link: MENU_PROJECT.document,
    icon: "docs",
  },
  {
    name: "Mon calendrier",
    link: MENU_PROJECT.calendar,
    icon: "calendar",
  },
  {
    name: "Mes photos",
    link: MENU_PROJECT.photo,
    icon: "picture",
  },
  {
    name: "Mon pense-bête",
    link: MENU_PROJECT.reminder,
    icon: "bulb",
  },
];
