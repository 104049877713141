import Breadcrumb from "../Common/Breadcrumb";
import Menu from "./Menu";

const Group = () => {
  //console.log('====================================');
  //console.log('Group()');
  return (
    <>
      <Breadcrumb
        icon="info"
        title="Plus qu'un Groupe, une Entité"
        links={[{ name: "Le Groupe" }]}
      />
      <Menu />
      <div className="bold mr10 text-uppercase mb20 fs16">
        <i className="si si-magnifier mr5 bold"></i>
        Qui sommes-nous ?
      </div>
      <div className="block hoverShadow">
        <div className="block-content minh500 text-justify fs18">
          <div className="fs16">
            <div className="bold mb20">
              Depuis 1989 SFMI intervient en tant que constructeur, promoteur et
              aménageur foncier.
            </div>
            <div className="row">
              <div className="col-12 col-lg-8">
                <div className="mt20">
                  Fort d'un esprit novateur associé à une volonté permanente
                  d'être leader dans son domaine, le groupe SFMI est devenu une
                  référence dans le secteur de la construction de maisons
                  individuelles.
                </div>
                <div className="mt20">
                  SFMI est le 1er constructeur de maisons individuelles en
                  Rhône-Alpes et se place au 3ème rang français.
                </div>
                <div className="mt20">
                  + de 1000 maisons sont construites chaque année par le Groupe,
                  son développement interne et externe permet de couvrir 70% du
                  territoire français.
                </div>
                <div className="mt20 mb20">
                  Nous proposons des produits de haute qualité à des tarifs très
                  attractifs. En effet, la puissance du groupe permet une
                  négociation directe des achats auprès des industriels en
                  supprimant tous les intermédiaires. Nos prestataires,
                  véritables partenaires, sont de réelles plateformes
                  logistiques, nous donnant ainsi la possibilité de maîtriser
                  les coûts. Les grands bénéficiaires : NOS CLIENTS !
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <img
                  src="/img/groupe/sfmi1.jpg"
                  width="100%"
                  className="text-center"
                  alt="SFMI"
                />
              </div>
            </div>
            <div className="row mt90 mb20 fw300">
              <div className="col-12 fs26 text-center">
                <div className="italic">
                  "Construire votre maison est un acte fort destiné à contribuer
                  à votre idéal de vie.
                </div>
                <div className="italic">
                  De cette belle responsabilité, nous en faisons un engagement
                  !"
                </div>
                <div className="mt20 fs16 fw400">La direction</div>
              </div>
            </div>
            <div className="row justify-content-center text-center mb140">
              <div className="col-md-6 col-xl-3 justify-content-center">
                <img
                  src="/img/groupe/sfmi2.jpg"
                  width="100%"
                  className="text-center"
                  alt="Main dans la main"
                />
              </div>
            </div>
            <div className="row justify-content-center text-center mb100">
              <div className="col-12 col-xl-3 mb20">
                <div className="w100p h150 mb5 overflow-hidden">
                  <img
                    src="/img/groupe/sfmi3.jpg"
                    width="100%"
                    alt="Paysage 1"
                  />
                </div>
                <div className="w100p h150 mb5 overflow-hidden">
                  <img
                    src="/img/groupe/sfmi4.jpg"
                    width="100%"
                    alt="Paysage 2"
                  />
                </div>
                <div className="w100p h150 mb5 overflow-hidden">
                  <img
                    src="/img/groupe/sfmi5.jpg"
                    width="100%"
                    alt="Paysage 3"
                  />
                </div>
                <div className="w100p h150 mb5 overflow-hidden">
                  <img
                    src="/img/groupe/sfmi6.jpg"
                    width="100%"
                    alt="Paysage 4"
                  />
                </div>
              </div>
              <div className="col-md-12 col-xl-6 text-justify mb20">
                <div>
                  Nous sommes présents sur les ¾ du territoire français.
                </div>
                <div>
                  Les 19 marques de SFMI couvrent 8 régions françaises :
                </div>
                <div className="mt25">
                  Bretagne, Pays de la Loire, Centre Val de Loire, Ile de
                  France, Bourgogne-Franche-Comté, Auvergne-Rhône-Alpes,
                  Languedoc-Roussillon-Midi-Pyrénées et Provence-Alpes-Côte
                  d'Azur avec plus de 100 points de vente sur 42 départements.
                </div>
                <div className="mt25">
                  Notre expérience associée à une parfaite connaissance de nos
                  régions, nous permet de construire depuis de longues années
                  des maisons personnalisées.
                </div>
                <div className="mt25">
                  Grâce à notre <span className="bold">indépendance</span> et
                  notre solidité financière, les plus grandes banques et
                  compagnies d'assurance nous soutiennent à hauteur de 220
                  millions d'euros, assurant ainsi des projets finalisés à
                  chacun de nos clients.
                </div>
                <div className="mt25">
                  Notre <span className="bold">expérience</span> associée à une
                  parfaite connaissance de nos régions, nous permet de
                  construire depuis de longues années des maisons
                  personnalisées. Nos compétences laissent envisager sereinement
                  la construction de votre projet. Notre{" "}
                  <span className="bold">responsabilité</span> est de vous
                  assurer une sélection d'entreprises et d'artisans qualifiés,
                  un choix de matériaux adaptés, un contrat de construction de
                  maison individuelle régi par la loi de 1990, une maison
                  conforme à toutes les réglementations techniques en vigueur.
                </div>
                <div className="mt25">
                  La <span className="bold">qualité</span> de nos prestations et
                  la coordination de chaque équipe sont des éléments phares qui
                  nous démarquent de la concurrence. Tout au long de votre
                  projet, vous bénéficierez d'une{" "}
                  <span className="bold">écoute</span> active qui vous permettra
                  de concrétiser vos envies dans leurs moindres détails.
                </div>
              </div>
              <div className="col-12 col-xl-3">
                <div className="w100p h150 mb5 overflow-hidden">
                  <img
                    src="/img/groupe/sfmi7.jpg"
                    width="100%"
                    alt="Paysage 5"
                  />
                </div>
                <div className="w100p h150 mb5 overflow-hidden">
                  <img
                    src="/img/groupe/sfmi8.jpg"
                    width="100%"
                    alt="Paysage 6"
                  />
                </div>
                <div className="w100p h150 mb5 overflow-hidden">
                  <img
                    src="/img/groupe/sfmi9.jpg"
                    width="100%"
                    alt="Paysage 7"
                  />
                </div>
                <div className="w100p h150 mb5 overflow-hidden">
                  <img
                    src="/img/groupe/sfmi10.jpg"
                    width="100%"
                    alt="Paysage 8"
                  />
                </div>
              </div>
            </div>
            <div className="row mt100 mb70">
              <div className="col-12 fs26 text-center fw300">
                <div className="italic">
                  Professionnalisme, Convivialité, Dynamisme, Implication...
                </div>
                <div className="italic">Notre rôle et Notre objectif.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Group;
