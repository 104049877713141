import Breadcrumb from "../Common/Breadcrumb";
import ImagePage from "../Project/Common/ImagePage";
import Box from "../../component/Common/Box";
import Users from "./Users";

const Account = () => {
  //console.log("====================================");
  //console.log("Account()");
  return (
    <>
      <Breadcrumb
        icon="user"
        title="Mon compte client"
        links={[{ name: "Mes informations personnelles" }]}
      />
      <ImagePage url="account" />
      <Box isHeader={false}>
        <span className="bold mr10 text-uppercase">
          <i className="si si-user mr5 bold"></i>
          Mon compte
        </span>
      </Box>
      <Box title="Mes informations personnelles">
        <Users />
      </Box>
    </>
  );
};

export default Account;
