import React from "react";
import { Link } from "react-router-dom";
import ENV from "../../constant/Environment";

const Breadcrumb = ({
  icon = "home",
  title = "Mon espace client",
  links = [],
}) => {
  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

  return (
    <div className="row mt30 mb30">
      <div className="col col-12">
        <h5>
          <i className={`si si-${icon} bundle-icon pt5`}></i>{" "}
          <div className="fl">
            {title}
            <div>
              <small className="font-w400 text-muted fs12">
                <Link to="/">Accueil</Link>
                {links.map((link, index) => {
                  return (
                    <span key={index}>
                      {" "}
                      /{" "}
                      {link.url ? (
                        <Link to={link.url}>{link.name}</Link>
                      ) : (
                        link.name
                      )}
                    </span>
                  );
                })}
              </small>
            </div>
          </div>
          {ENV.isDev() && (
            <button
              onClick={() => clearCacheData()}
              className="btn btn-md btn-light fr"
            >
              Clear cache
            </button>
          )}
        </h5>
      </div>
    </div>
  );
};

export default React.memo(Breadcrumb);
