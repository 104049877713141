import { Link } from "react-router-dom";

const Btn = ({ name, nb, icon, url, suffix = "disponible" }) => {
  const end =
    "disponible" !== suffix ? suffix : nb > 1 ? "disponibles" : "disponible";

  return (
    <div className="col col-12 col-lg-4">
      <div className="block hoverShadow">
        <div className="block-content">
          <div className="bg-light text-center pt20">
            <div className="item item-rounded mx-auto">
              <i className={`si si-${icon} fs30`}></i>
            </div>
            <div className="fs16 mb10">
              {nb} {name}
              {nb > 1 ? "s" : ""} {end}
            </div>
            <div className="text-center p10 pb20">
              <Link
                to={url}
                className="btn btn-square btn-xs btn-outline-secondary"
              >
                Visualiser
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Btn;
