import { Link, Outlet } from "react-router-dom";
import Header from "./Header";

const Layout = () => {
  return (
    <>
      <Header />
      <main id="main-container">
        <div className="container" id="container-content">
          <Outlet />
        </div>
      </main>
      <div id="footer">
        <div className="block p10 mb0 text-center">
          <Link to="/mentions-legales">Mentions légales</Link>
          <div className="mt10">
            <Link to="/">
              <img src="/img/sfmi.png" height="40" alt="Logo" />
            </Link>
            <div className="mt10">
              © 2002 - 2023 SFMI
              <div className="fs11">
                Société Française de Maisons Individuelles
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
