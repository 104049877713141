const Tile = ({ data, index }) => {
  return (
    <div className="block hoverShadow">
      <div className="block-content p0">
        <div className="review-img">
          <img
            src={data.photo}
            className="w100p overflow-hidden"
            alt={data.who}
          />
        </div>
        <div className="review-title" style={{ padding: 20, paddingBottom: 0 }}>
          {data.who} {index}
          <div className="fr fs12 color-c">{data.date}</div>
          <div className="clear"></div>
        </div>
        <div style={{ padding: 20, paddingTop: 0 }}>{data.text}</div>
      </div>
    </div>
  );
};

export default Tile;
