import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getBearerSelector } from "../../selectors";
import { setReviewsAction } from "../../actions";
import { API_URL } from "../Url";

export const useFetchReview = () => {
  const bearer = useSelector(getBearerSelector);
  const dispatch = useDispatch();

  const getReviews = async () => {
    try {
      const response = await axios.get(`${API_URL}/reviews`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `bearer ${bearer}`,
        },
      });
      dispatch(setReviewsAction(response.data));
    } catch (e) {
      return [];
    }
  };

  return {
    getReviews,
  };
};
