import { useDispatch } from "react-redux"
import { setProjectSelectedAction } from "../actions"

export const DispatchProject = () => {
    const dispatch = useDispatch()

    const setProjectSelected = (project) => {
        dispatch(setProjectSelectedAction(project));
    }

    return {
        setProjectSelected
    }
}