import React from "react";
import Breadcrumb from "../../Common/Breadcrumb";
import ImagePage from "../Common/ImagePage";
import Information from "../../Common/Information";
import ChooseProject from "../../Common/ChooseProject";
import BlockRight from "../Common/BlockRight";
import Menu from "../Common/Menu";
import Categories from "../Reminder/Categories";
import { MENU_PROJECT } from "../../../constant/url/UrlProject";

const Reminders = () => {
  //console.log("====================================");
  //console.log("Reminders()");

  return (
    <>
      <Breadcrumb
        title="Mon pense-bête"
        icon="briefcase"
        links={[
          { name: "Mon projet", url: MENU_PROJECT.project },
          { name: "Mon pense-bête" },
        ]}
      />
      <ImagePage url="reminder" />
      <Menu />
      <div className="row">
        <div className="col col-12 col-xl-9">
          <Information />
          <ChooseProject />
          <Categories />
        </div>
        <div className="col col-12 col-xl-3">
          <BlockRight />
        </div>
      </div>
    </>
  );
};

export default Reminders;
