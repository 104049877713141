import { GET_REVIEWS } from "../actions/actionsType";

const defaultState = {
  reviews: [],
};

export const reviewReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_REVIEWS:
      return {
        reviews: action.payload.data,
      };
    default:
      return state;
  }
};
