import { Link } from "react-router-dom";
import { useState } from "react";
import { DispatchMenu } from "../redux/dispatch/dispatchMenu";
import Menu from "./Menu";

const Header = () => {
  const { setActiveMenu } = DispatchMenu();
  const [displayMenu, setDisplayMenu] = useState(false);
  const [displayHamburger, setDisplayHamburger] = useState(true);

  const handleMenu = (link) => {
    setActiveMenu(link);
    setDisplayMenu(false);
    setDisplayHamburger(true);
  };

  const handleDisplay = () => {
    setDisplayMenu((prev) => !prev);
    setDisplayHamburger((prev) => !prev);
  };

  return (
    <header>
      <nav className="container">
        <div id="nav-full">
          <Menu handleMenu={handleMenu}>
            <li>
              <Link to="/" onClick={() => handleMenu("")}>
                <div id="logo-container">
                  <div id="logo">
                    <img src="/img/sfmi.png" height="100%" alt="Logo SFMI" />
                  </div>
                </div>
              </Link>
            </li>
          </Menu>
        </div>
        <div id="nav-mobile">
          <Link to="/" onClick={() => handleMenu("")} className="d-flex">
            <div id="logo-container">
              <div id="logo">
                <img src="/img/sfmi.png" alt="Logo SFMI" />
              </div>
            </div>
          </Link>
          <div className="d-flex justify-content-end text-end">
            <div
              id="js-nav"
              className={`pointer pt10 pb10 p15 ${
                displayHamburger ? "" : "d-none"
              }`}
              onClick={handleDisplay}
            >
              <i className="fa fa-bars fs16"></i>
            </div>
            <div
              id="nav-popin-close"
              className={`pointer p20 ${displayHamburger ? "d-none" : ""}`}
              onClick={handleDisplay}
            >
              <i className="fa fa-times fs16"></i>
            </div>
          </div>
        </div>
      </nav>

      <div id="nav-popin" className={displayMenu ? "" : "d-none"}>
        <div id="nav-popin-header"></div>
        <Menu handleMenu={handleMenu} />
      </div>
    </header>
  );
};

export default Header;