import { Link } from "react-router-dom";
import { menus } from "../../constant/MenuGroup";

const Menu = () => {
  //console.log('Menu()');
  const pathname = window.location.pathname;
  return (
    <div className="margin-block mb50" id="nav-project">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          // justifyContent: "space-between",
          alignItems: 'flex-start',
          gap: 20,
          flexWrap: "wrap",
        }}
      >
        {menus.map((menu, index) => {
          return (
            <div
              className=""
              key={index}
              style={{ flex: 1, minWidth: 120, flexWrap: "wrap" }}
            >
              <Link
                to={menu.link}
                className={menu.link === pathname ? "active" : ""}
              >
                {menu.name}
                <div className="mt20">
                  <i className={`si si-${menu.icon} fs30`}></i>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Menu;
