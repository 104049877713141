import { GET_EVENTS } from "../actions/actionsType";

const defaultState = {
  events: [],
};

export const eventReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_EVENTS:
      return {
        events: action.payload.data,
      };
    default:
      return state;
  }
};
