import axios from "axios";
import { useSelector } from "react-redux";
import { getBearerSelector, getProjectSelectedSelector } from "../../selectors";
import { API_URL } from "../Url";
import { useFetchAuth } from "../auth/useFetchAuth";

export const useFetchTchat = () => {
  const bearer = useSelector(getBearerSelector);
  const project = useSelector(getProjectSelectedSelector);
  const { logout } = useFetchAuth();

  const getTchats = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/project/${project.token}/tchats`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `bearer ${bearer}`,
          },
        }
      );
      return response.data;
    } catch (e) {
      return [];
    }
  };

  const getTchats2 = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/project/${project.token}/tchats2`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `bearer ${bearer}`,
          },
        }
      );
      return response.data;
    } catch (e) {
      return [];
    }
  };

  const getApiComments2 = async (tchatToken) => {
    try {
      const response = await axios.get(`${API_URL}/tchat/v2/${tchatToken}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `bearer ${bearer}`,
        },
      });
      return response.data;
    } catch (e) {
      return [];
    }
  };

  const getApiComments = async (tchatToken) => {
    try {
      const response = await axios.get(`${API_URL}/tchat/${tchatToken}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `bearer ${bearer}`,
        },
      });
      return response.data.comments;
    } catch (e) {
      return [];
    }
  };

  const setApiComment = async (newComment) => {
    try {
      await axios.post(
        `${API_URL}/tchat/${newComment.tchatToken}`,
        newComment,
        {
          headers: {
            Authorization: `bearer ${bearer}`,
          },
        }
      );
      return true;
    } catch (error) {
      return false;
    }
  };

  const setApiCommentNote = async (commentToken, rating) => {
    try {
      await axios.post(
        `${API_URL}/tchat/comment/${commentToken}`,
        {
          rating,
        },
        {
          headers: {
            Authorization: `bearer ${bearer}`,
          },
        }
      );
      return true;
    } catch (error) {
      return false;
    }
  };

  return {
    getTchats,
    getApiComments,
    setApiComment,
    setApiCommentNote,
    getTchats2,
    getApiComments2
  };
};
