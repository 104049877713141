import Breadcrumb from "../Common/Breadcrumb";
import Menu from "./Menu";

const Prestation = () => {
  return (
    <>
      <Breadcrumb
        icon="info"
        title="Plus qu'un Groupe, une Entité"
        links={[{ name: "Le Groupe", url: '/groupe' }, {name:'Règlementation'}]}
      />
      <Menu />
      <div className="bold mr10 text-uppercase mb20 fs16">
        <i className="si si-list mr5 bold"></i>
        Les prestations
      </div>
      <div className="block hoverShadow">
        <div className="block-content">
          <div className="bold text-uppercase">
            1/ La construction de votre maison
          </div>
        </div>
      </div>
      <div className="row fs18 fw300 mb50">
        <div className="col-12 col-md-6">
          <div className="block hoverShadow">
            <div className="block-content">
              <div className="bold">Épisode 1</div>
              <div className="mb20">
                Votre maison est connectée et intelligente
              </div>
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  className="embed-responsive-item w100p"
                  src="https://www.youtube.com/embed/W9T5Iv6t90c?rel=0"
                  allowFullScreen
                  title="Votre maison est connectée et intelligente"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="block hoverShadow">
            <div className="block-content">
              <div className="bold">Épisode 2</div>
              <div className="mb20">Les murs de votre maison</div>
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  className="embed-responsive-item w100p"
                  src="https://www.youtube.com/embed/xtK8NolSmmA?rel=0"
                  allowFullScreen
                  title="Les murs de votre maison"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-xl-4">
          <div className="block hoverShadow">
            <div className="block-content">
              <div className="bold">Épisode 3</div>
              <div className="mb20">Votre toit en tuiles béton</div>
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  className="embed-responsive-item w100p"
                  src="https://www.youtube.com/embed/Z7u1F-VXFa0?rel=0"
                  allowFullScreen
                  title="Votre toit en tuile béton"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-xl-4">
          <div className="block hoverShadow">
            <div className="block-content">
              <div className="bold">Épisode 4</div>
              <div className="mb20">Votre toit en tuiles terre cuite</div>
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  className="embed-responsive-item w100p"
                  src="https://www.youtube.com/embed/OsyKecKbMYc?rel=0"
                  allowFullScreen
                  title="Votre toit en tuiles terre cuite"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-xl-4">
          <div className="block hoverShadow">
            <div className="block-content">
              <div className="bold">Épisode 5</div>
              <div className="mb20">Le chauffage au sol de votre maison</div>
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  className="embed-responsive-item w100p"
                  src="https://www.youtube.com/embed/DWjpl2yz99U?rel=0"
                  allowFullScreen
                  title="Le chauffage au sol de votre maison"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="block hoverShadow">
        <div className="block-content">
          <div className="bold text-uppercase">2/ Les garanties</div>
        </div>
      </div>
      <div className="block hoverShadow fs16 fw300 text-justify">
        <div className="block-content">
          <div className="mb20">
            <div className="bold">
              SFMI : un constructeur tourné vers votre bien-être
            </div>
            C'est tout d'abord la sécurité et toutes les garanties d'un grand
            constructeur. SFMI s'engage à sécuriser chaque étape de votre projet
            en intégrant toutes les garanties conformes au CCMI Garantie de
            livraison à prix et délais convenus La garantie de livraison à prix
            et délais convenus entre en vigueur le jour de l'ouverture du
            chantier et prend fin à la réception des travaux. La garantie de
            livraison à prix et délais convenus est obligatoire pour tous les
            constructeurs, les entrepreneurs, les maîtres d'œuvre ou les
            artisans qui se lancent dans la construction d'une maison. Elle
            permet de protéger l'acheteur contre un risque éventuel
            d'inachèvement ou de mauvaise exécution des travaux prévus au
            contrat de départ. Même en cas de défaillance du constructeur,
            l'acheteur est assuré que la maison sera construite dans les
            meilleures conditions, dans ce cas le garant désigne un repreneur
            qui prendra le relais et se chargera de la poursuite des travaux au
            même prix et dans des délais identiques. AXA France IARD ou HCC
            INTERNATIONAL INSURANCE COMPANY ou AMTRUST EUROPE LTD ou CECG
            Compagnie Européenne des Garanties et Cautions ou TOKIO MARINE
            EUROPE SA (TME)
          </div>

          <div className="mb20">
            <div className="bold">Garantie décennale</div>
            Elle porte sur une durée de 10 ans. Selon la loi n° 78-12 du 4
            janvier 1978 dite loi Spinetta les constructeurs ou tous les
            prestataires liées au maître d'ouvrage par un contrat, un devis, une
            convention doivent souscrire à la date d'ouverture de chantier une
            assurance de responsabilité civile décennale. Il s'agit de la RC
            décennale ou RCD Elle couvre tous les vices ou malfaçons du logement
            qui affectent l'étanchéité, la solidité du logement ou qui rendent
            le bien immobilier insalubre voire inhabitable (par exemple, suite à
            une infiltration d'eau). Elle comprend le sol, les murs, la
            charpente, la toiture ou encore les escaliers de la construction.
            Cette garantie ne fonctionne pas lorsqu'un problème survient suite à
            un défaut d'entretien de la part du propriétaire. AVIVA ou AXA
            France IARD
          </div>

          <div className="mb20">
            <div className="bold">Assurance Dommages-Ouvrage</div>
            Elle prend effet à partir de la date de réception des travaux et
            pendant 10 ans Selon la loi Spinetta l'assurance dommages ouvrage
            doit être contractée par tout maître d'ouvrage La garantie dommages
            ouvrage couvre toutes les malfaçons causées par le constructeur et
            compromettant la solidité de l'ouvrage ou son habitabilité même
            résultant d'un vice du sol. En revanche elle ne garantit pas les
            éléments d'équipement comme les portes et fenêtres. Son intérêt est
            d'intervenir dans le cas ou survient des dommages de nature
            décennale sans recherche de responsabilité. En d'autres termes la
            garantie dommage ouvrage vous protège face aux vices de
            construction. En revanche cette assurance construction ne couvre pas
            les sinistres suite à incendies ou catastrophes naturelles. Dans la
            mesure où ces risques sont couverts par l'assurance habitation. De
            même les dommages esthétiques et ceux consécutifs à un acte de
            négligence ne sont pas couverts par exemple : défaut d'entretien,
            fenêtre laissée ouverte. Dès lors, l'assureur dommage ouvrage
            pré-finance rapidement les réparations du sinistre sans recherche de
            responsabilité, sans attendre une décision de justice. Il entamera
            ensuite des recours contre les constructeurs défaillants et leurs
            garanties décennales.
            <br />
            (AVIVA ou AXA France IARD)
          </div>

          <div className="mb20">
            <div className="bold">Garantie de remboursement de l'acompte</div>
            La garantie de remboursement d'acompte permet la restitution des
            acomptes versés par le maître d'ouvrage au constructeur. La garantie
            de remboursement d'acompte, strictement encadrée par la loi n°
            90-1129 du 19 décembre 1990 relative au contrat de construction
            d'une maison individuelle permet la restitution des acomptes versés
            par le maître d'ouvrage en cas de défaillance du constructeur. (HCC
            INTERNATIONAL INSURANCE COMPANY ou AMTRUST EUROPE LTD ou CECG
            Compagnie Européenne des Garanties et Cautions ou TOKIO MARINE
            EUROPE SA (TME) ou AXA France IARD)
          </div>

          <div className="mb20">
            <div className="bold">Garantie de parfait achèvement</div>
            Elle a une durée de validité d'un an suivant le jour de la livraison
            de l'ouvrage Elle est prévue par la loi du 4 janvier 1978. Son champ
            d'action est élargi au maximum puisque tous les dysfonctionnements,
            malfaçons ou défauts entraineront une réparation effectuée par le
            constructeur. Le logement livré doit être sans défaut, d'où le nom
            de la garantie. Les frais sont à la charge du constructeur. L'usure
            normale n'est en revanche pas couverte.
          </div>

          <div className="mb20">
            <div className="bold">Garantie biennale de bon fonctionnement</div>
            Sa durée d'action est de 2 ans à partir du jour de livraison du
            bien. Elle est prévue dans l'article 1792-3 du Code Civil et est
            complémentaire à l'assurance Dommages-Ouvrage. La garantie est
            effective si les dommages n'affectent pas le gros œuvre et ne
            proviennent pas d'un mauvais entretien du propriétaire.
            <div className="mt10">
              La garantie biennale couvre tous les éléments et les équipements
              d'un ouvrage non couverts par la garantie décennale :
            </div>
            <ul>
              <li>livrés avec l'ouvrage et installés avant sa réception,</li>
              <li>dissociables de l'ouvrage,</li>
              <li>
                dont le remplacement ou le retrait ne rend pas l'ouvrage
                impropre à sa destination,
              </li>
              <li>voués à fonctionner, par opposition aux éléments inertes</li>
            </ul>
            La couverture comprend entre autres :
            <ul>
              <li>les portes intérieures,</li>
              <li>
                les éléments de plomberie : (éviers, radiateurs, sanitaires,
                robinetterie, canalisations, chauffe eau, ballon d'eau chaude,
                etc.),
              </li>
              <li>
                les appareils électriques fournis à la livraison (interphones,
                appareils de domotique, système de ventilation et de
                climatisation),
              </li>
              <li>les faux plafonds et cloisons mobiles,</li>
              <li>les volets,</li>
              <li>etc.</li>
            </ul>
            Garantie biennale et couverture : quelques cas particuliers
            Plomberie : La jurisprudence considère que si les travaux concernent
            des équipements peu importants (radiateurs, robinets…), alors cela
            relève de la garantie biennale. En revanche, les dommages
            conséquents susceptibles de rendre le bâtiment impropre à sa
            destination relève de la garantie décennale. Par conséquent de la
            garantie dommages ouvrage si vous l'avez souscrite. Peinture : De
            manière générale, la garantie de bon fonctionnement ne s'applique
            pas pour la peinture, les revêtements muraux et les enduits
            esthétiques. C'est alors la responsabilité contractuelle qui la
            remplace. Concernant la peinture et enduits ayant une fonction
            technique (isolation, imperméabilisation, anti-corrosion), c'est
            l'assurance décennale et l'assurance dommages ouvrage qui
            s'applique. Revêtement au sol : Le carrelage ou la moquette
            concerne-t-il la garantie biennale ou décennale ? Bien qu'étant
            dissociables de l'ouvrage, ces éléments ne sont pas concernés par la
            notion de fonctionnement. Pour cette raison, ils n'entrent pas dans
            le cadre de la garantie biennale. Dans le cas d'un carrelage ou une
            moquette mal posés, c'est l'assurance décennale des entrepreneurs
            qui entre en jeu.
          </div>
        </div>
      </div>
    </>
  );
};

export default Prestation;
