
import Breadcrumb from "../../Common/Breadcrumb";
import ImagePage from "../Common/ImagePage";
import Menu from "../Common/Menu";
import Information from "../../Common/Information";
import ChooseProject from "../../Common/ChooseProject";
import Partners from "../../Common/Partners";
import BlockRight from "../Common/BlockRight";
import "../../../constant/style/project/calendar.css";
import Categories from "../Photo/Categories";
import { MENU_PROJECT } from "../../../constant/url/UrlProject";

const Photos = () => {
  //console.log("====================================");
  //console.log("Photo()");
  
  return (
    <>
      <Breadcrumb
        icon="briefcase"
        title="Un projet dans les règles de l'art"
        links={[{ name: "Mon projet", url: MENU_PROJECT.project }, { name: "Mes photos" }]}
      />
      <ImagePage url="photo" />
      <Menu />
      <div className="row">
        <div className="col col-12 col-xl-9">
          <Information />
          <ChooseProject />
          <Categories />
          <Partners />
        </div>
        <div className="col col-12 col-xl-3">
          <BlockRight />
        </div>
      </div>
    </>
  );
};

export default Photos;
