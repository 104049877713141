import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  getDocumentsSelector,
  getProjectSelectedSelector,
} from "../../../redux/selectors";
import { useFetchDocument } from "../../../redux/api/document/useFetchDocument";
import Types from "./Types";

const Categories = ({ data }) => {
  const { getApiDocuments } = useFetchDocument();
  const project = useSelector(getProjectSelectedSelector);
  const documents = useSelector(getDocumentsSelector);

  useEffect(() => {
    getApiDocuments();
  }, [project]);

  return (
    <>
      {documents.length &&
        documents.map((category, index) => (
          <div className="block hoverShadow" key={category.token}>
            <div className="block-header bg-modern">
              <h3 className="block-title color-f fw300">
                Documents de type <span className="fw700">{category.name}</span>
              </h3>
            </div>
            <div className="block-content">
              <Types data={category.types} />
            </div>
          </div>
        ))}
    </>
  );
};

export default Categories;
