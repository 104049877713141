import { Link } from "react-router-dom";
const Tile = ({ category = null, all }) => {
    const cats = null !== category ? all.filter((n) => n.categoryId === category.id) : all;
    return (
      <div className="row" style={{ display: "flex" }}>
        {cats.map((n) => {
          // return cat.news.map((n) => {
            return (
              <div className="col col-12 col-md-6 col-lg-4" key={n.token}>
                <div className="block hoverShadow">
                  <div
                    className="block-content"
                    style={{
                      minHeight: 400,
                      justifyContent: "space-between",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        maxHeight: 200,
                        overflow: "hidden",
                        alignItems: "center",
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      <img src={n.photo} alt={`${n.name}`} width="100%" />
                    </div>
                    <div>
                      <div className="mt10 mb10 news-title">{n.name}</div>
                      <div className="mb10 truncate-news">{n.shortText}</div>
                    </div>
                    <div className="text-right" style={{ marginTop: "auto" }}>
                      <Link
                        to={`/actualites/${n.token}`}
                        className="btn btn-square btn-xs btn-info"
                      >
                        Lire la suite
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            );
          // });
        })}
      </div>
    );
  };

export default Tile;