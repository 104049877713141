import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./component/Layout";
import Tchat from "./component/Tchat";
import News from "./component/News";
import Group from "./component/Group";
import Review from "./component/Review";
import Auth from "./component/Auth";
import AuthGuard from "./component/AuthGuard";
import Logout from "./component/Logout";
import NewsDetail from "./component/News/NewsDetail";
import Legacy from "./component/Legacy";
import GroupPartners from "./component/Group/Partners";
import Documents from "./component/Project/Document";
import Calendar from "./component/Project/Calendar";
import Photos from "./component/Project/Photo";
import Reminders from "./component/Project/Reminder";
import Project from "./component/Project/Home";
import Error from "./component/Error";
import Prestation from "./component/Group/Prestation";
import Reglementation from "./component/Group/Reglementation";
import Account from "./component/Account";
import Detail from "./component/Tchat/Detail";
import "./constant/style/oneui.css";
import "./constant/style/App.css";
import "./constant/style/class.css";
import "./constant/style/menu.scss";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/identification" element={<Auth />} />
        <Route
          path="/*"
          element={
            <AuthGuard>
              <Routes>
                <Route element={<Layout />}>
                  <Route index element={<Project />} />
                  <Route path="mon-compte" element={<Account />} />
                  <Route path="documents" element={<Documents />} />
                  <Route path="calendrier" element={<Calendar />} />
                  <Route path="photos" element={<Photos />} />
                  <Route path="pense-bete" element={<Reminders />} />
                  <Route path="tchat" element={<Tchat />} />
                  <Route path="tchat/:token" element={<Detail />} />
                  <Route path="actualites" element={<News />} />
                  <Route path="actualites/:token" element={<NewsDetail />} />
                  <Route path="groupe">
                    <Route path="" element={<Group />} />
                    <Route path="prestations" element={<Prestation />} />
                    <Route path="partenaires" element={<GroupPartners />} />
                    <Route path="reglementation" element={<Reglementation />} />
                  </Route>
                  <Route path="livre-or" element={<Review />} />
                  <Route path="mentions-legales" element={<Legacy />} />
                  <Route path="deconnexion" element={<Logout />} />
                  <Route path="*" element={<Error />} />
                </Route>
              </Routes>
            </AuthGuard>
          }
        />
        <Route path="*" element={<Error />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
