import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getUserSelector } from "../redux/selectors";

const AuthGuard = ({ children }) => {
  const user = useSelector(getUserSelector);
  if (null === user) {
    return <Navigate to="/identification" />;
  }
  return children;
};

export default AuthGuard;
