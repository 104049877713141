import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  getSelectedNewsAction,
  getNewsAction,
  getNewsCategoriesAction,
} from "../../actions";
import { API_URL } from "../Url";
import { getBearerSelector, getNewsCategoriesSelector } from "../../selectors";

export const useFetchNews = () => {
  const dispatch = useDispatch();
  const bearer = useSelector(getBearerSelector);
  const newsCategories = useSelector(getNewsCategoriesSelector);

  const getApiNews = async () => {
    try {
      const response = await axios.get(`${API_URL}/news`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `bearer ${bearer}`,
        },
      });
      dispatch(getNewsAction(response.data));
      dispatch(getSelectedNewsAction(response.data[0]));
      return true;
    } catch (error) {
      return [];
    }
  };

  const getApiNewsByCategories = async (token) => {
    try {
      const response = await axios.get(`${API_URL}/news/categories`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `bearer ${bearer}`,
        },
      });
      dispatch(getNewsCategoriesAction(response.data));
      return true;
    } catch (error) {
      return [];
    }
  };

  const getApiNewsDetail = async (token) => {
    if (newsCategories.length) {
      let detail = [];
      newsCategories.forEach((c) => {
        c.news.forEach((n) => {
          if (n.token === token) {
            detail = n;
          }
        });
      });
      if (detail) {
        return detail;
      }
    }

    try {
      const response = await axios.get(`${API_URL}/news/${token}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `bearer ${bearer}`,
        },
      });
      return response.data;
    } catch (error) {
      return false;
    }
  };

  return {
    getApiNews,
    getApiNewsByCategories,
    getApiNewsDetail,
  };
};
