import { Link } from "react-router-dom";
import Breadcrumb from "../Common/Breadcrumb";
import Menu from "./Menu";

const Reglementation = () => {
  return (
    <>
      <Breadcrumb
        icon="info"
        title="Plus qu'un Groupe, une Entité"
        links={[
          { name: "Le Groupe", url: "/groupe" },
          { name: "Règlementation" },
        ]}
      />
      <Menu />
      <div className="bold mr10 text-uppercase mb20 fs16">
        <i className="si si-share mr5 bold"></i>
        Réglementations
      </div>
      <div className="block hoverShadow" id="rt2012">
        <div className="block-content">
          <div className="bold text-uppercase">
            Historique des Réglementations Thermiques
          </div>
        </div>
      </div>
      <div className="block hoverShadow">
        <div className="block-content">
          <div className="mb20">
            <div className="bold">LA RT 1974, SUITE AU PREMIER CHOC PÉTROLIER</div>
            La première réglementation a été instaurée par Pierre Mesmer suite
            au premier choc pétrolier de 1973, qui a déclenché une prise de
            conscience de la nécessité d'économiser l'énergie. Dès 1974, la
            première RT est mise en place. S'appliquant uniquement aux bâtiments
            neufs d'habitation, elle a pour objectif de baisser de 25% la
            consommation énergétique des bâtiments. Pour ce faire, la RT 1974
            imposait uniquement la mise en place d'une fine couche d'isolation
            et l'installation d'une régulation automatique des systèmes de
            chauffage.
          </div>

          <div className="mb20">
            <div className="bold">LA RT 1982, SUITE AU SECOND CHOC PÉTROLIER</div>
            Le second choc pétrolier de 1979 va aboutir à la publication de la
            RT 1982. L'objectif de cette nouvelle réglementation thermique est
            une réduction de 20% de la consommation d'énergie des bâtiments par
            rapport à la RT 1974.
          </div>

          <div className="mb20">
            <div className="bold">
              LA RT 1988, EXTENSION AUX BÂTIMENTS TERTIAIRES
            </div>
            Cette nouvelle réglementation inclue des exigences de performances
            minimales de l'enveloppe et des systèmes mis en place. La RT 1988
            est étendue aux bâtiments non résidentiels.
          </div>

          <div className="mb20">
            <div className="bold">
              LA RT 2000, AJOUT D'UNE EXIGENCE SUR LE CONFORT D'ÉTÉ
            </div>
            Contrairement aux précédentes réglementations, qui imposaient
            uniquement des exigences de moyens, la RT2000 voit l'apparition
            d'une exigence de performance globale du bâtiment mais aussi de
            confort d'été, via le coefficient TIC. La RT2000 vise une réduction
            de 20% de la consommation maximale des logements par rapport à la RT
            1988 et une baisse de 40% de la consommation des bâtiments
            tertiaires.
          </div>

          <div className="mb20">
            <div className="bold">
              LA RT 2005, PRISE EN COMPTE DU BIOCLIMATISME ET DES ÉNERGIES
              RENOUVELABLES
            </div>
            Cette réglementation reprend les bases de la RT2000, avec une
            augmentation des exigences de consommations et des gardes fous. La
            RT2005 vise une nouvelle baisse de 15% de la consommation
            énergétique des bâtiments neufs et des extensions.
          </div>

          <div className="mb20">
            <div className="bold">LA RT 2012, UN SAUT EN AVANT</div>
            La RT2012 est la réglementation en vigueur depuis le 1er janvier
            2013. A quelques exceptions près (bâtiments et parties de bâtiment
            dont la température normale d'utilisation est inférieure ou égale à
            12 °C; bâtiments ou parties de bâtiment destinés à rester ouverts
            sur l'extérieur en fonctionnement habituel; constructions
            provisoires prévues pour une durée d'utilisation de moins de deux
            ans; bâtiments ou parties de bâtiment qui, en raison de contraintes
            spécifiques liées à leur usage, doivent garantir des conditions
            particulières de température, d'hygrométrie ou de qualité de l'air,
            et nécessitant de ce fait des règles particulières; bâtiments ou
            parties de bâtiment chauffés ou refroidis pour un usage dédié à un
            procédé industriel; bâtiments agricoles ou d'élevage; bâtiments
            situés dans les départements d'outre-mer…) elle s'applique à tous
            les projets de constructions en France. Très exigeante, la RT2012 se
            base sur l'ancien label BBC (Bâtiment Basse Consommation).
            Globalement, l'exigence réglementaire a été divisée par trois entre
            1974 et 2012.
          </div>

          <div className="mb20">
            <div className="bold">
              LA RT 2020, VERS DES BÂTIMENTS À ÉNERGIE POSITIVE ?
            </div>
            L'application de la RE 2020 (RT 2020) est repoussée à l'été 2021
          </div>

          <div className="row">
            <div className="col-12 col-lg-6 mb20">
              <img
                src="/img/groupe/RT2012-emissions-gaz-effet-serrre.jpg"
                className="w100p"
                alt="RT2012 emissions gaz effet serrre"
              />
            </div>
            <div className="col-12 col-lg-6 mb20">
              <img
                src="/img/groupe/evolution-reglementation-thermique.jpg"
                className="w100p"
                alt="evolution reglementation thermique"
              />
            </div>
          </div>

          <div className="mb20">
            <div className="bold">
              Points clés de la réglementation actuelle : la RT2012
            </div>
            La réglementation thermique 2012 comporte 3 exigences de résultats :
            <ul>
              <li>besoins bioclimatiques du bâti (Bbiomax),</li>
              <li>consommation d'énergie primaire (Cepmax),</li>
              <li>confort en été (Ticréf).</li>
            </ul>
            La réglementation comporte également des exigences de moyens
            (étanchéité, surface vitrée, affichage des consommations d'énergie,
            etc.) et des garde-fous performantiels (traitement des ponts
            thermiques).
          </div>

          <div className="mb20">
            <div className="bold">
              Limiter les besoins énergétiques du bâtiment par une conception
              bioclimatique
            </div>
            Concevoir de manière bioclimatique un bâtiment signifie que sa
            construction tient compte des impacts environnementaux (exposition
            au soleil, au vent…) : elle limite donc les contraintes liées à cet
            environnement et en tire les bénéfices. Cette efficacité énergétique
            minimale du bâti est définie par le coefficient Bbiomax (besoins
            bioclimatiques du bâti). L'objectif est de limiter les besoins en
            énergie du bâtiment tout en garantissant un niveau de confort pour
            les occupants.
          </div>

          <div className="mb20">
            <div className="bold">
              Des équipements performants pour une consommation énergétique
              réduite
            </div>
            L'exigence de consommation conventionnelle maximale d'énergie
            primaire se traduit par le coefficient Cepmax. Elle porte sur les
            consommations de chauffage, de refroidissement, d'éclairage, de
            production d'eau chaude sanitaire et d'auxiliaires (pompes et
            ventilateurs). Sa valeur s'élève à 50 kWhEP/m²/an et est modulée
            selon la localisation géographique, l'altitude, le type d'usage du
            bâtiment, la surface moyenne des logements et les émissions de gaz à
            effet de serre pour le bois énergie et les réseaux de chaleur les
            moins émetteurs de CO2. Cette exigence impose, en plus de
            l'optimisation du bâti exprimée par le Bbio, le recours à des
            équipements énergétiques performants, à haut rendement.
          </div>

          <div className="mb20">
            <div className="bold">
              L'exigence de confort d'été dans les bâtiments non climatisés
            </div>
            La RT 2012 définit des catégories de bâtiments dans lesquels il est
            possible d'assurer un bon niveau de confort en été sans avoir à
            recourir à un système actif de refroidissement. Ces catégories (CE1
            et CE2) dépendent du type d'occupation et de la localisation (zone
            climatique, altitude, proximité de zones de bruit). Pour ces
            bâtiments, la réglementation impose que la température la plus
            chaude atteinte dans les locaux, au cours d'une séquence de 5 jours
            très chauds d'été, n'excède pas un certain seuil. Cette exigence
            améliore le confort des occupants en période de forte chaleur
            (notamment les impacts des canicules) et constitue également une
            mesure importante pour limiter le recours à la climatisation.
          </div>

          <div className="mb20">
            <div className="bold">Contrôle de l'application de la RT 2012</div>
            Des contrôles des règles de construction (CRC) sont réalisés chaque
            année sur un échantillon de nouvelles constructions. Ils permettent
            de sensibiliser l'ensemble des acteurs au respect des règles de
            construction, à de bonnes pratiques professionnelles et à une
            meilleure qualité des bâtiments. Ils contribuent par ailleurs à
            améliorer la compréhension des textes réglementaires. Le contrôle de
            l'application de la RT 2012 est amélioré, grâce aux dispositions
            suivantes :
            <ul>
              <li>
                l'établissement d'une attestation de prise en compte de la RT
                2012 à deux étapes clés du processus de construction : à la
                demande de permis de construire et à l'achèvement du bâtiment,
              </li>
              <li>
                l'édition par les logiciels d'un récapitulatif standardisé
                d'étude thermique (RSET).
              </li>
            </ul>
            Le RSET pourra être exploité par :
            <ul>
              <li>
                le maître d'œuvre pour optimiser le projet de construction,
              </li>
              <li>
                le maître d'ouvrage pour une meilleure connaissance du bâtiment
                qui lui a été livré,
              </li>
              <li>
                le diagnostiqueur établissant le diagnostic de performance
                énergétique (DPE) pour les bâtiments neufs,
              </li>
              <li>
                les différents intervenants en charge d'attester de
                l'application de la RT 2012,
              </li>
              <li>l'agent assermenté de l'État en charge du CRC.</li>
            </ul>
            Les données RSET anonymisées sont désormais accessibles en libre
            accès.
          </div>

          <div className="mb20">
            <div className="bold">RT2012 pour les professionnels</div>
            La RT 2012 a pour objectif de limiter la consommation d'énergie
            primaire des bâtiments neufs à un maximum de 50 kWhEP/m²/an en
            moyenne, tout en suscitant :
            <ul>
              <li>
                une évolution technologique et industrielle significative pour
                toutes les filières du bâti et des équipements,
              </li>
              <li>
                un très bon niveau de qualité énergétique du bâti,
                indépendamment du choix de système énergétique,
              </li>
              <li>
                un équilibre technique et économique entre les énergies
                utilisées pour le chauffage et la production d'eau chaude
                sanitaire.
              </li>
            </ul>
            La méthode de calcul Th-B-C-E 2012 a pour objet de décrire le calcul
            réglementaire. Elle n'a cependant pas vocation à faire le calcul des
            consommations réelles, compte tenu des conventions retenues,
            notamment pour les apports extérieurs de chaleur, les températures
            de consigne et les horaires d'occupation. Cette méthode de calcul
            utilise comme données d'entrée tous les éléments descriptifs du
            bâtiment et de ses équipements qui sont définis de façon opposable.
            Les éléments apportés après la réception du bâtiment, ainsi que les
            paramètres indépendants du bâtiment intervenant dans la méthode,
            sont définis de façon conventionnelle : il s'agit notamment des
            données climatiques et celles relatives à l'occupation et l'usage
            des bâtiments. Des logiciels d'application intégrant le moteur de
            calcul sont distribués par des éditeurs de logiciels. Ils permettent
            de vérifier la conformité d'un projet à la RT2012.
          </div>

          <div className="mb20">
            <div className="bold">
              Attestations de prise en compte de la réglementation thermique
              2012
            </div>
            L'objectif du dispositif est de contribuer à l'amélioration de la
            performance énergétique des bâtiments neufs en attestant de la prise
            en compte de la réglementation thermique. Cela se traduit par la
            réalisation de deux documents à établir à deux moments clés du
            processus de construction : au dépôt de la demande de permis de
            construire et à l'achèvement des travaux de construction d'un
            bâtiment.
          </div>

          <div className="mb20">
            <div className="bold">
              Attestation à établir au dépôt de la demande de permis de
              construire
            </div>
            Le maître d'ouvrage atteste de la prise en compte de la
            réglementation thermique 2012. Le document permet également
            d'attester de la réalisation de l'étude de faisabilité des
            approvisionnements en énergie. Cette étude de faisabilité a été
            introduite par la loi n° 2005-781 du 13 juillet 2005 de
            programmation fixant les orientations de la politique énergétique de
            la France (loi POPE). Ainsi, le document à établir au dépôt de la
            demande de permis de construire permet de s'assurer :
            <ul>
              <li>
                de la prise en compte de la conception bioclimatique du bâtiment
                au plus tôt du projet grâce au coefficient Bbio, gage d'atteinte
                d'un niveau de performance énergétique élevé,
              </li>
              <li>
                que la réflexion sur les systèmes énergétiques a été engagée, en
                particulier le recours aux énergies renouvelables, en indiquant
                des éléments de conclusion de l'étude faisabilité des
                approvisionnements en énergie.
              </li>
            </ul>
          </div>

          <div className="mb20">
            <div className="bold">
              Attestation à établir à l'achèvement des travaux
            </div>
            Le maître d'ouvrage atteste de la prise en compte de la
            réglementation thermique 2012 par le maître d'œuvre, si celui-ci a
            reçu une mission de conception de l'opération et de l'exécution des
            travaux, ou sinon par le maître d'ouvrage lui-même. En situation
            d'intervention d'une maîtrise d'œuvre, le document peut être établi
            par l'un des quatre professionnels suivants : architecte,
            diagnostiqueur pour la maison, bureau de contrôle, organisme de
            certification si le bâtiment fait l'objet d'une certification. Il
            permet de s'assurer de la prise en compte de la réglementation
            thermique, en vérifiant :
            <ul>
              <li>
                les trois exigences de résultats de la RT 2012 (besoin
                bioclimatique, consommation d'énergie primaire, confort d'été),
              </li>
              <li>
                la cohérence entre l'étude thermique qui a été conduite et le
                bâtiment construit en vérifiant certains points clés (production
                d'énergie, étanchéité à l'air du bâtiment, énergie renouvelable,
                isolation) par un contrôle visuel sur site ou de documents.
              </li>
            </ul>
            Lien officiel :{" "}
            <Link
              to="https://www.ecologique-solidaire.gouv.fr/exigences-reglementaires-construction-des-batiments"
              target="_blank"
            >
              https://www.ecologique-solidaire.gouv.fr/exigences-reglementaires-construction-des-batiments
            </Link>
          </div>
        </div>
      </div>
      <div className="block hoverShadow" id="plu">
        <div className="block-content">
          <div className="bold text-uppercase">Le Plan Local d'Urbanisme (PLU)</div>
        </div>
      </div>
      <div className="block hoverShadow">
        <div className="block-content fs14">
          <div className="mb20">
            Le <span className="bold">Plan Local d'Urbanisme (PLU)</span> regroupe
            l'ensemble des différents documents visant à assurer le bon
            développement urbain de nos villes. Il remplace progressivement les
            anciens Plan d'Occupation des Sols POS) et Règlement National
            d'Urbanisme (RNU).
          </div>

          <div className="mb20">
            <div className="bold">
              Définition : Qu'est ce qu'un Plan Local d'urbanisme (PLU) ?
            </div>
            Le Plan Local d'Urbanisme organise le développement de votre commune
            en fixant des règles d'urbanisme et des règles de construction selon
            un découpage précis en différentes zones. Il permet d'encadrer
            rigoureusement les projets urbains, leurs styles architecturaux,
            leurs impacts sur l'environnement collectif et sur le développement
            durable. Cet ensemble de plans et de documents sert notamment aux
            municipalités, aux différents constructeurs et acteurs urbains, aux
            architectes mais aussi aux citoyens lorsqu'ils sont en demande de
            permis de construire ou de déclaration préalable de travaux.
          </div>

          <div className="mb20">
            <div className="bold">
              Que contient un Plan Local d'Urbanisme (PLU) ?
            </div>
            On retrouve dans les documents du PLU les documents suivants :
            <ul>
              <li>
                Un rapport de présentation avec une analyse détaillée de
                l'environnement.
              </li>
              <li>
                Le PADD, le Projet d'Aménagement et de développement durable.
              </li>
              <li>
                Le règlement urbain concernant les différentes zones et les
                orientations d'aménagement applicables.
              </li>
              <li>
                Les différentes annexes. Qui peuvent être différentes
                informations sur les réseaux de raccordement, le Plan de
                Prévention des Risques et des Inondations (PPRI), etc.
              </li>
            </ul>
          </div>

          <div className="mb20">
            <div className="bold">
              Quelles sont les règles d'un Plan Local d'Urbanisme (PLU) ?
            </div>
            Le Plan Local d'Urbanisme a la vocation première d'harmoniser nos
            villes et d'assurer une cohérence territoriale. Aussi, de garantir
            une homogénéité dans les constructions, protéger les quartiers à
            proximité de biens classés et historiques, de conserver zones
            agricole et naturelle. Ces documents municipaux régissent également
            vos servitudes, les accès de votre terrain à la voie publique, vos
            raccordements aux différents réseaux type eau potable, électricité,
            réseaux internet etc. Il indique les possibilités de
            constructibilité pour chaque parcelle. Indique les délimitations
            avec les voisins, les hauteurs de construction, les matériaux à
            utiliser, l'aspect architectural général, etc.
          </div>

          <div className="mb20">
            <div className="bold">
              Quelles sont les différentes zones d'un Plan Local d'Urbanisme
              (PLU) ?
            </div>
            La Plan Local d'urbanisme est découpé en plan de zonage très précis.
            On y trouve principalement les zones suivantes :
            <ul>
              <li>
                Les zones urbaines (zone U) : elles sont déjà équipées et
                urbanisées. L'équipement de réseaux collectifs d'assainissement
                est suffisant pour accueillir les constructions nouvelles.
              </li>
              <li>
                Les zones à urbaniser (zone AU) : il s'agit de zones à caractère
                naturel destinées à accueillir de futurs habitants ou de
                nouvelles entreprises. Cependant, l'urbanisation peut être
                progressive.
              </li>
              <li>
                Les zones agricoles (zone A) : elles sont réservées aux
                activités agricoles et au logement des seuls agriculteurs
              </li>
              <li>
                Les zones naturelles (zone N) : elles sont préservées pour leur
                paysage, leur faune et leur flore.
              </li>
            </ul>
            Ces différentes zones U, AU, A, N peuvent être succédées d'autres
            lettres. Cela permet de les découper encore plus précisément. À
            chacune des lettres ou ensemble de lettres, des règles spécifiques.
            Le tout étant détaillé dans le règlement du PLU :
            <Link
              to="https://www.collectivites-locales.gouv.fr/documents-durbanisme-et-regles-generales-durbanisme"
              target="_blank"
            >
              https://www.collectivites-locales.gouv.fr/documents-durbanisme-et-regles-generales-durbanisme
            </Link>
          </div>

          <div className="mb20">
            <div className="bold">Où trouver le Plan Local d'Urbanisme (PLU) ?</div>
            Vous pouvez demander le Plan Local d'Urbanisme directement au
            comptoir de votre mairie ou en adressant une demande par email. Pour
            certaines communes, communautés de communes ou métropoles vous
            pouvez le trouver directement sur internet. Il est possible que
            votre commune ne soit pas régie par un PLU. Si tel est le cas, elle
            est soit régie par un POS ou un RNU ou rien du tout. D'ici quelques
            années, l'ensemble des 36000 communes de France sera soumise au PLU
            et dans le meilleur des monde, il sera possible de les trouver
            facilement sur les sites internet officiels du gouvernement !
          </div>

          <div className="mb20">
            <div className="bold">
              Le permis de construire et le Plan Local d'Urbanisme
            </div>
            Le Plan Local d'Urbanisme est indissociable du permis de construire
            et de la déclaration préalable de travaux. Vos projets décrits dans
            ces autorisations d'urbanisme sont intégralement comparés aux
            règlements de votre commune. Les zonages du PLU définissent
            également si votre projet nécessite un permis de construire ou une
            déclaration de travaux en fonction de la surface de plancher ou
            d'emprise au sol que vous souhaitez créer.
          </div>

          <div className="mb20">
            <div className="bold">Informations complémentaires</div>
            <div>
              Qu'est-ce qu'un Plan Local d'Urbanisme Intercommunale (PLUi) ?
            </div>
            Un Plan Local d'Urbanisme Intercommunale est exactement le même
            document que le PLU classique. Cependant, le PLUi s'applique à une
            échelle beaucoup plus large que le simple territoire communal. Il
            est alors élaboré par la collectivité intercommunale compétente.
            Généralement ces documents sont constitués pour les moyennes et
            grandes villes.
            <div>
              Qu'est-ce qu'est le Projet d'Aménagement et de développement
              durable (PADD) ?
            </div>
            Le Projet d'Aménagement et de développement durable (PADD) est un
            document exprimant les objectifs et projets de la commune ou
            collectivité en matière de développement économique et social,
            environnemental et urbanistique. Il donne un aperçu du développement
            de la ville à horizon 10 à 20 ans.
            <div>Qu'est-ce qu'un Plan de Prévention des risque (PPR) ?</div>
            Un Plan de Prévention des risques est un document réglementant
            l'utilisation des sols de votre commune et stipulant les risques
            auxquels cette dernière peut être soumise. Notamment en terme
            d'inondations, de mouvements de terrain, d'incendies de forêt,
            d'avalanches, de tempêtes, de submersions marines, de séismes,
            d'éruption volcaniques, de cyclones, etc.). Ce document définit les
            zones réglementaires : les zones inconstructibles, les zones
            constructibles sous certaines conditions avec la prise en compte des
            risques.
          </div>
        </div>
      </div>
    </>
  );
};

export default Reglementation;
