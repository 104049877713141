import moment from "moment";

const getDateFr = (date) => {
  const dt = moment(date);
  return dt.format("DD/MM/YYYY");
};

const getDateTime = (date, withSecondes = false) => {
  const dt = moment(date);
  const day = dt.format("DD/MM/YYYY");
  const hour = dt.format("HH");
  const min = dt.format("mm");
  const sec = dt.format("ss");
  return day + " " + hour + "h" + min + (withSecondes ? "'" + sec : "");
};

const getNow = () => {
  const dt = moment();
  return dt.format("YYYY-MM-DDTHH:mm:ssP");
};

const doExport = {
  getDateFr,
  getDateTime,
  getNow,
};

export default doExport;
