import { useState } from "react";
import DATE from "../../constant/date";

const Response = ({ onAddComment, tchatToken }) => {
  const [text, setText] = useState("");

  const save = () => {
    if ("" !== text) {
      const newComment = {
        date: DATE.getNow(),
        isNeedAnswer: true,
        new: true,
        text,
        tchatToken,
      };
      onAddComment(newComment);
    }
    setText("");
  };

  return (
    <div className="p20 bg-light tchat-add-comment tchat-focus-add">
      <div className="bold mb15">
        <i className="si si-bubbles fs20 mr5"></i>
        Ajouter un commentaire
      </div>
      <textarea
        name="text"
        className="form-control  minh100"
        placeholder="Votre commentaire"
        required=""
        onChange={(e) => setText(e.target.value)}
        value={text}
      />
      <button
        type="submit"
        className="btn btn-square btn-sm pl20 pr20 btn-success mt10"
        name="valider"
        onClick={save}
      >
        Enregistrer
      </button>
    </div>
  );
};

export default Response;
