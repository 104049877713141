import { Link } from "react-router-dom";
import Breadcrumb from "../Common/Breadcrumb";
import Menu from "./Menu";

const GroupPartners = () => {
  return (
    <>
      <Breadcrumb
        icon="info"
        title="Plus qu'un Groupe, une Entité"
        links={[{ name: "Le Groupe", url: '/groupe' }, {name:'Règlementation'}]}
      />
      <Menu />
      <div className="bold mr10 text-uppercase mb20 fs16">
        <i className="si si-share mr5 bold"></i>
        Les partenaires
      </div>
      <div className="block hoverShadow">
        <div className="block-content minh500">
          <div className="row">
            <div className="col-12 col-md-6 col-xl-4 mb40">
              <div className="row">
                <div className="col-12 text-center">
                  <div className="fournisseur-title">Briques</div>
                  <div className="fs12 mb10">
                    Hautes performances thermiques,
                    <br />
                    acoustiques, sismiques et feu
                  </div>
                </div>
                <div className="col-12 text-center relative">
                  <img
                    src="/img/fournisseur/prestations-cmyhome-image-brique-wienerberger.jpg"
                    width="75%"
                    className="fournisseur-img"
                    alt="brique wienerberger"
                  />
                </div>
                <div className="col-12 text-center">
                  <img
                    src="/img/fournisseur/prestations-cmyhome-logo-wienerberger.png"
                    height="150"
                    alt="logo wienerberger"
                  />
                  <div className="mt10 mb10 fs12">
                    <Link to="https://www.wienerberger.fr/" target="_blank">
                      https://www.wienerberger.fr/
                    </Link>
                  </div>
                  <div className="pb20 border-bottom-primary fs12">
                    Leader mondial favorisant les savoirs faire humains et
                    techniques locaux et les matières premières extraites
                    localement
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-4 mb40">
              <div className="row">
                <div className="col-12 text-center">
                  <div className="fournisseur-title">Tuiles</div>
                  <div className="fs12 mb10">
                    Terre cuite et béton. Le choix et la qualité Norme NF et
                    garantie 30 ans résistance au gel
                  </div>
                </div>
                <div className="col-12 text-center relative">
                  <img
                    src="/img/fournisseur/prestations-cmyhome-image-tuiles-monier.jpg"
                    width="75%"
                    className="fournisseur-img"
                    alt="tuile monier"
                  />
                </div>
                <div className="col-12 text-center">
                  <img
                    src="/img/fournisseur/prestations-cmyhome-logo-monier.png"
                    height="150"
                    alt="logo monier"
                  />
                  <div className="mt10 mb10 fs12">
                    <Link to="http://monier.fr" target="_blank">
                      http://monier.fr
                    </Link>
                  </div>
                  <div className="pb20 border-bottom-primary fs12">
                    Le plus grand fabricant de solutions de couverture
                    <br />
                    et d'étanchéité pour le toit en Europe
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-4 mb40">
              <div className="row">
                <div className="col-12 text-center">
                  <div className="fournisseur-title">Enduits</div>
                  <div className="fs12 mb10">
                    Depuis 40 ans ces enduits professionnels apportent la plus
                    grande satisfaction. L'environnement allié à la performance
                  </div>
                </div>
                <div className="col-12 text-center relative">
                  <img
                    src="/img/fournisseur/prestations-cmyhome-image-enduit-parexlanko.jpg"
                    width="75%"
                    className="fournisseur-img"
                    alt="enduit parexlanko"
                  />
                </div>
                <div className="col-12 text-center">
                  <img
                    src="/img/fournisseur/prestations-cmyhome-logo-parexlanko.png"
                    height="150"
                    alt="logo parexlanko"
                  />
                  <div className="mt10 mb10 fs12">
                    <Link to="https://www.parexlanko.com/fr" target="_blank">
                      https://www.parexlanko.com/fr
                    </Link>
                  </div>
                  <div className="pb20 border-bottom-primary fs12">
                    Engagé depuis plusieurs années dans une démarche volontaire
                    et collective de développement durable
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-4 mb40">
              <div className="row">
                <div className="col-12 text-center">
                  <div className="fournisseur-title">Portes intérieures</div>
                  <div className="fs12 mb10">
                    Fabrication française de portes intérieures de grande
                    qualité et répondant aux normes d'isolation thermique,
                    phonique et au feu
                  </div>
                </div>
                <div className="col-12 text-center relative">
                  <img
                    src="/img/fournisseur/prestations-cmyhome-image-portes-righini.jpg"
                    width="75%"
                    className="fournisseur-img"
                    alt="porte righini"
                  />
                </div>
                <div className="col-12 text-center">
                  <img
                    src="/img/fournisseur/prestations-cmyhome-logo-righini.png"
                    height="150"
                    alt="logo righini"
                  />
                  <div className="mt10 mb10 fs12">
                    <Link to="https://www.righini.com" target="_blank">
                      https://www.righini.com
                    </Link>
                  </div>
                  <div className="pb20 border-bottom-primary fs12">
                    Une entreprise 100% française familiale spécialisé dans les
                    portes depuis 60 ans
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-4 mb40">
              <div className="row">
                <div className="col-12 text-center">
                  <div className="fournisseur-title">Portes extérieures</div>
                  <div className="fs12 mb10">
                    Eco-conçues en France à haut niveau d'isolation thermique,
                    phonique et d'étanchéité AEV
                  </div>
                </div>
                <div className="col-12 text-center relative">
                  <img
                    src="/img/fournisseur/prestations-cmyhome-image-portes-belm.jpg"
                    width="75%"
                    className="fournisseur-img"
                    alt="porte bel m"
                  />
                </div>
                <div className="col-12 text-center">
                  <img
                    src="/img/fournisseur/prestations-cmyhome-logo-bel-m.png"
                    height="150"
                    alt="logo bel m"
                  />
                  <div className="mt10 mb10 fs12">
                    <Link to="https://www.belm.fr" target="_blank">
                      https://www.belm.fr
                    </Link>
                  </div>
                  <div className="pb20 border-bottom-primary fs12">
                    Spécialiste de la porte d'entrée française sur-mesure pour
                    maison individuelle
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-4 mb40">
              <div className="row">
                <div className="col-12 text-center">
                  <div className="fournisseur-title">Isolation</div>
                  <div className="fs12 mb10">
                    Une des meilleurs solutions d'isolation permettant de
                    répondre à la norme RT2012
                  </div>
                </div>
                <div className="col-12 text-center relative">
                  <img
                    src="/img/fournisseur/prestations-cmyhome-image-isolation-knauf.jpg"
                    width="75%"
                    className="fournisseur-img"
                    alt="isolation knauf"
                  />
                </div>
                <div className="col-12 text-center">
                  <img
                    src="/img/fournisseur/prestations-cmyhome-logo-knauf.png"
                    height="150"
                    alt="logo knauf"
                  />
                  <div className="mt10 mb10 fs12">
                    <Link to="https://www.knauf.fr" target="_blank">
                      https://www.knauf.fr
                    </Link>
                  </div>
                  <div className="pb20 border-bottom-primary fs12">
                    Un expert reconnu depuis 1932 spécialisé dans les solutions
                    d'isolation pour les maisons individuelles
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-4 mb40">
              <div className="row">
                <div className="col-12 text-center">
                  <div className="fournisseur-title">
                    Carrelages et faïences
                  </div>
                  <div className="fs12 mb10">
                    Fort de son expérience clients, Decoceram a élaboré une
                    gamme alliant choix et qualité
                  </div>
                </div>
                <div className="col-12 text-center relative">
                  <img
                    src="/img/fournisseur/prestations-cmyhome-image-carrelage-et-faiences-decoceram.jpg"
                    width="75%"
                    className="fournisseur-img"
                    alt="carrelage et faiences decoceram"
                  />
                </div>
                <div className="col-12 text-center">
                  <img
                    src="/img/fournisseur/prestations-cmyhome-logo-decoceram.png"
                    height="150"
                    alt="logo decoceram"
                  />
                  <div className="mt10 mb10 fs12">
                    <Link to="https://www.decoceram.fr" target="_blank">
                      https://www.decoceram.fr
                    </Link>
                  </div>
                  <div className="pb20 border-bottom-primary fs12">
                    Le spécialiste français du carrelage et de la décoration
                    reconnu par les professionnels et les possesseurs de maisons
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-4 mb40">
              <div className="row">
                <div className="col-12 text-center">
                  <div className="fournisseur-title">Domotique</div>
                  <div className="fs12 mb10">
                    Energeasy Connect de Rexel rend votre maison connectée plus
                    autonome en vous libérant des contraintes du quotidien
                  </div>
                </div>
                <div className="col-12 text-center relative">
                  <img
                    src="/img/fournisseur/prestations-cmyhome-image-domotique-rexel.jpg"
                    width="75%"
                    className="fournisseur-img"
                    alt="domotique rexel"
                  />
                </div>
                <div className="col-12 text-center">
                  <img
                    src="/img/fournisseur/prestations-cmyhome-logo-rexel.png"
                    height="150"
                    alt="rexel"
                  />
                  <div className="mt10 mb10 fs12">
                    <Link to="https://www.energeasyconnect.com" target="_blank">
                      https://www.energeasyconnect.com
                    </Link>
                  </div>
                  <div className="pb20 border-bottom-primary fs12">
                    Rexel se classe parmi les entreprises les plus performantes
                    de la "Climate Change A List"
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-4 mb40">
              <div className="row">
                <div className="col-12 text-center">
                  <div className="fournisseur-title">
                    Appareillage électrique
                  </div>
                  <div className="fs12 mb10">
                    Performance dans le temps, certitude de disposer d'appareils
                    très sécurisés et esthétiques permettant une intégration
                    discrète
                  </div>
                </div>
                <div className="col-12 text-center relative">
                  <img
                    src="/img/fournisseur/prestations-cmyhome-image-prises-legrand-et-schneider.jpg"
                    width="75%"
                    className="fournisseur-img"
                    alt="legrand-et-schneider"
                  />
                </div>
                <div className="col-12 text-center">
                  <img
                    src="/img/fournisseur/prestations-cmyhome-logo-legrand.png"
                    height="150"
                    alt="legrand"
                  />
                  <div className="mt10 mb10 fs12">
                    <Link to="https://www.legrand.fr" target="_blank">
                      https://www.legrand.fr
                    </Link>
                  </div>
                  <div className="pb20 border-bottom-primary fs12">
                    Un groupe français spécialiste mondial des infrastructures
                    électriques
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GroupPartners;
