import { useFetchAuth } from "../../redux/api/auth/useFetchAuth";
import PHONE from "../../constant/phone";
import { useState } from "react";

const Person = ({ data }) => {
  const { updatePerson } = useFetchAuth();
  const [person, setPerson] = useState({
    token: data.token,
    mobile: data.mobile,
    email: data.email
  });
  const handleInfoChange = (text, type) => {
    setPerson((prev) => ({...prev, [type]: text}))
  };
  const onSubmit = () => {
    updatePerson(person)
  }
  return (
    <div className="col-12 col-xl-6">
      <table className="table table-bordered table-striped mb40">
        <thead>
          <tr>
            <th
              colSpan={2}
              className={`color-f ${
                data.civility.id === 2 ? "bg-smooth" : "bg-modern"
              }`}
            >
              {data.firstname} {data.lastname}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th className="w1 text-nowrap pr40">Tél. portable</th>
            <td>
              <input
                className="form-control"
                value={person.mobile}
                onChange={(e) => handleInfoChange(e.target.value, 'mobile')}
                autoComplete="chrome-off"
              />
            </td>
          </tr>
          <tr>
            <th className="w1 text-nowrap">Email</th>
            <td>
              <input
                className="form-control"
                value={person.email}
                onChange={(e) => handleInfoChange(e.target.value, 'email')}
                autoComplete="chrome-off"
              />
            </td>
          </tr>
          <tr>
            <th className="w1 text-nowrap"></th>
            <td>
              <button
                className="btn btn-success btn-md"
                onClick={onSubmit}
              >Enregistrer</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Person;
