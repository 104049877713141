import { GET_DOCUMENTS } from "../actions/actionsType";

const defaultState = {
  documents: [],
};

export const documentReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_DOCUMENTS:
      return {
        documents: action.payload.data,
      };
    default:
      return state;
  }
};
