import { GET_PHOTOS } from "../actions/actionsType";

const defaultState = {
  photos: [],
};

export const photoReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_PHOTOS:
      return {
        photos: action.payload.data,
      };
    default:
      return state;
  }
};
