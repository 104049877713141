import {
  GET_NEWS,
  GET_NEWS_CATEGORIES,
  GET_SELECTED_NEWS,
} from "../actions/actionsType";

const defaultState = {
  news: [],
  selectedNews: null,
  newsCategories: [],
};

export const newsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_NEWS:
      return {
        news: action.payload.data,
        selectedNews: state.selectedNews,
        newsCategories: state.newsCategories,
      };
    case GET_SELECTED_NEWS:
      return {
        news: state.news,
        selectedNews: action.payload.data,
        newsCategories: state.newsCategories,
      };
    case GET_NEWS_CATEGORIES:
      return {
        news: state.news,
        selectedNews: state.selectedNews,
        newsCategories: action.payload.data,
      };
    default:
      return state;
  }
};
