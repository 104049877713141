const wordwrap = (str, width, brk = " ", cut = true) => {
  brk = brk || "n";
  width = width || 75;
  cut = cut || false;
  if (!str) {
    return str;
  }
  var regex =
    ".{1," + width + "}(s|$)" + (cut ? "|.{" + width + "}|.+$" : "|S+?(s|$)");
  return str.match(RegExp(regex, "g")).join(brk);
};

const intro = (text, length = 10) => {
  const pos = text.indexOf(" ", length);

  if (text.length < length) {
    return text;
  }

  if (-1 === pos) {
    return text.substr(0, length) + "...";
  }

  return text.substr(0, pos) + "...";
};

const random = (nb = 50) => {
  const alpha = Array.from(Array(26)).map((e, i) => i + 97);
  const alphabet = alpha.map((x) => String.fromCharCode(x));
  const num = Array.from(Array(10)).map((e, i) => i + 48);
  const numbers = num.map((x) => String.fromCharCode(x));
  const chars = alphabet.concat(numbers);
  let random = "";

  while (random.length < nb) {
    random += chars[Math.floor(Math.random() * chars.length)];
  }

  return random;
};

const doExport = {
  wordwrap,
  intro,
  random,
};

export default doExport;
