const Photo = ({data}) => {
    return (
        <div key={data.token} style={{ display: "flex" }}>
          <img
            src={data.url}
            alt={data.url}
            style={{ width: 110 }}
          />
        </div>
      );
}

export default Photo;