import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getEventsAction } from "../../actions";
import { API_URL } from "../Url";
import { getBearerSelector } from "../../selectors";

export const useFetchEvent = () => {
  const dispatch = useDispatch();
  const bearer = useSelector(getBearerSelector);

  const getApiEvents = async (project) => {
    try {
      const response = await axios.get(
        `${API_URL}/project/${project.token}/events`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `bearer ${bearer}`,
          },
        }
      );
      dispatch(getEventsAction(response.data));
      return response.data;
    } catch (error) {
      // logout();
      return [];
    }
  };

  return {
    getApiEvents,
  };
};
