import React from "react";

const ImagePage = ({url}) => {
  //console.log('ImagePage()');
    return (
        <div className="row" id="project-slide">
        <div className="col">
          <div
            className="block margin-block maxh300 relative"
            style={{ overflow: "hidden" }}
          >
            <img src={`/data/${url}.jpg`} className="w100p" alt="project" />
          </div>
        </div>
      </div>
    )
}

export default React.memo(ImagePage);