import { Link } from "react-router-dom";

const Docs = ({ data }) => {
  return (
    <>
      {data.map((doc, index) => {
        return (
          <div className="block-file p10" key={doc.token}>
            <i className="far fa-file-pdf fs16 mr10"></i>
            <span>
              {doc.name} {data.length > 1 ? index + 1 : ""}
            </span>
            <div className="fr pull-right">
              <Link
                to={doc.url}
                // target="_blank"
                download={`${doc.name} ${data.length > 1 ? index + 1 : ""}.pdf`}
                // download
                title="Télécharger"
              >
                <i className="fa fa-download fs16"></i>
              </Link>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Docs;
