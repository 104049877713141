// export const MENU_PROJECT = {
//     project: '/projet',
//     document: '/projet/documents',
//     calendar: '/projet/calendrier',
//     photo: '/projet/photos',
//     insurance: '/projet/assurance',
//     reminder: '/projet/pense-bete',
// }

export const MENU_PROJECT = {
    project: '/',
    document: '/documents',
    calendar: '/calendrier',
    photo: '/photos',
    insurance: '/assurance',
    reminder: '/pense-bete',
}