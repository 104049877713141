import { combineReducers } from "redux";
import { userReducer } from "./user";
import { videoReducer } from "./video";
import { newsReducer } from "./news";
import { eventReducer } from "./event";
import { documentReducer } from "./document";
import { photoReducer } from "./photo";
import { reminderReducer } from "./reminder";
import { menuReducer } from "./menu";
import { partnerReducer } from "./partner";
import { reviewReducer } from "./review";

export const rootReducers = combineReducers({
  userReducer,
  videoReducer,
  newsReducer,
  eventReducer,
  documentReducer,
  photoReducer,
  reminderReducer,
  menuReducer,
  partnerReducer,
  reviewReducer,
});
