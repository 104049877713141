import React from "react";
import { useSelector } from "react-redux";
import { getProjectSelectedSelector } from "../../../redux/selectors";

const Employee = () => {
  const project = useSelector(getProjectSelectedSelector);
  return (
    <>
      <div className="block-header block-header-default mb10 border-left">
        <h3 className="block-title slash">
          Mes interlocuteurs
          <i className="si si-bubbles fs24 color-c fr"></i>
        </h3>
      </div>

      <div className="push">
        <div className="bold text-flat" href="#">
          <i className="si si-info bold mr5"></i>
          Commercial
        </div>
        <p className="mt-1">
          {project.seller.firstname} {project.seller.lastname}
        </p>
      </div>

      {project.commercialAssistant && (
        <div className="push">
          <div className="bold text-flat" href="#">
            <i className="si si-info bold mr5"></i>
            Assistante commerciale
          </div>
          <p className="mt-1">
            {project.commercialAssistant.firstname}{" "}
            {project.commercialAssistant.lastname}
          </p>
        </div>
      )}

      {project.technicalAssistant && (
        <div className="push">
          <div className="bold text-flat" href="#">
            <i className="si si-info bold mr5"></i>
            Assistante technique
          </div>
          <p className="mt-1">
            {project.technicalAssistant.firstname}{" "}
            {project.technicalAssistant.lastname}
          </p>
        </div>
      )}

      {project.workSupervisor && (
        <div className="push">
          <div className="bold text-flat" href="#">
            <i className="si si-info bold mr5"></i>
            Responsable technique
          </div>
          <p className="mt-1">
            {project.workSupervisor.firstname} {project.workSupervisor.lastname}
          </p>
        </div>
      )}
    </>
  );
};

export default React.memo(Employee);