import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getProjectSelectedSelector } from "../../redux/selectors";
import { useFetchTchat } from "../../redux/api/tchat/useFetchTchat";

const Tchats = () => {
  const { getTchats2 } = useFetchTchat();
  const project = useSelector(getProjectSelectedSelector);
  const [tchats, setTchats] = useState([]);

  useEffect(() => {
    const getApi = async () => {
      setTchats(await getTchats2());
    };
    getApi();
  }, [project]);

  return (
    <div className="row">
      {tchats.length ? (
        tchats.map((tchat) => (
          <div className="col-12 col-lg-6" key={tchat.token}>
            <Link to={`/tchat/${tchat.token}`}>
              <div className="block hoverShadow text-center">
                <div className="block-header pointer bg-modern"></div>
                <div className="block-content">
                  <div className="mb20">
                    <i className="si si-bubbles fs48"></i>
                  </div>
                  <div className="fs14 mb10 bold color4">{tchat.name}</div>
                  <div className="fs11 color9">
                    {tchat.nbComments} commentaire
                    {tchat.nbComments > 1 ? "s" : ""}
                  </div>
                </div>
              </div>
            </Link>
          </div>
        ))
      ) : (
        <div className="col-12">
          <div className="block hoverShadow">
            <div className="block-content">
              <div className="">
                <i className="si si-bubbles bundle-icon fs24"></i>
              </div>
              Aucun tchat pour le moment.
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Tchats;
