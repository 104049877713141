export const getBearerSelector = (store) => store.userReducer.bearer;
export const getUserSelector = (store) => store.userReducer.user;
export const getPersonsSelector = (store) => store.userReducer.persons;
export const getProjectsSelector = (store) => store.userReducer.projects;
export const getProjectSelectedSelector = (store) => store.userReducer.projectSelected;
export const getVideosSelector = (store) => store.videoReducer.videos;
export const getSelectedVideoSelector = (store) => store.videoReducer.selectedVideo;
export const getNewsSelector = (store) => store.newsReducer.news;
export const getSelectedNewsSelector = (store) => store.newsReducer.selectedNews;
export const getNewsCategoriesSelector = (store) => store.newsReducer.newsCategories;
export const getEventsSelector = (store) => store.eventReducer.events;
export const getDocumentsSelector = (store) => store.documentReducer.documents;
export const getPhotosSelector = (store) => store.photoReducer.photos;
export const getRemindersSelector = (store) => store.reminderReducer.reminders;
export const getRemindersCheckedSelector = (store) => store.reminderReducer.remindersChecked;
export const getRemindersTotalSelector = (store) => store.reminderReducer.remindersTotal;
export const getActiveMenuSelector = (store) => store.menuReducer.activeMenu;
export const getPartnersSelector = (store) => store.partnerReducer.partners;
export const getReviewsSelector = (store) => store.reviewReducer.reviews;
