import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useFetchTchat } from "../../redux/api/tchat/useFetchTchat";
import Breadcrumb from "../Common/Breadcrumb";
import Information from "../Common/Information";
import Comment from "./Comment";
import Response from "./Response";

const Detail = () => {
  const { token } = useParams();
  const { getApiComments2, setApiComment } = useFetchTchat();
  const [isOpen, setIsOpen] = useState(true);
  const [tchat, setTchat] = useState(null);
  let intvl = null;
  const secondes = 5;

  const getComments = async () => {
    setTchat(await getApiComments2(token));
  };

  useEffect(() => {
    getComments();
    intvl = setInterval(() => {
      getComments();
    }, secondes * 1000);
    return () => clearInterval(intvl);
  }, []);

  const handleComments = async (newComment) => {
    await setApiComment(newComment);
    getComments();
  };

  if (null === tchat) {
    return <div>Merci de patienter</div>;
  }

  return (
    <>
      <Breadcrumb
        icon="bubbles"
        title="Entre vous et nous"
        links={[
          { name: "Discussion", url: "/tchat" },
          { name: tchat.name },
        ]}
      />
      <Information />
      <div
        className={`block hoverShadow ${!isOpen ? "block-mode-hidden" : ""}`}
      >
        <div
          className="block-header pointer bg-modern"
          onClick={() => setIsOpen(!isOpen)}
        >
          <h3 className="block-title color-f">
            {tchat.name}
            <span className="fr">
              {tchat.comments.length} commentaire
              {tchat.comments.length > 1 ? "s" : ""}
            </span>
          </h3>
        </div>
        <div className="block-content p0">
          <div className="p20">
            {tchat.comments.length ? (
              tchat.comments.map((comment, index) => {
                return (
                  <Comment
                    data={comment}
                    key={comment.token}
                    isLast={index === tchat.comments.length - 1}
                  />
                );
              })
            ) : (
              <div>Aucun commentaire pour le moment.</div>
            )}
          </div>
          <hr className="m0"></hr>
          <Response onAddComment={handleComments} tchatToken={token} />
        </div>
      </div>
    </>
  );
};

export default Detail;
