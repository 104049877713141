import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getNewsSelector } from "../../../redux/selectors";
import { useFetchNews } from "../../../redux/api/news/useFetchNews";
import ARRAY from "../../../constant/array";

const News = () => {
  const { getApiNews } = useFetchNews();
  const news = useSelector(getNewsSelector);
  const [randomNews, setRandomNews] = useState([]);

  useEffect(() => {
    if (!news.length) {
      getApiNews();
    } else {
      let shuffle = ARRAY.shuffle(news).slice(0, 3);
      setRandomNews(shuffle);
    }
  }, [news]);

  return (
    <>
      <div className="block-header block-header-default mb20 border-left">
        <h3 className="block-title slash">
          Mon actualité
          <i className="si si-book-open fs24 color-c fr"></i>
        </h3>
      </div>
      {randomNews.length ? (
        randomNews.map((n) => {
          return (
            <div className="push" key={n.token}>
              <Link
                className="bold text-modern"
                to="/actualites/77ljk59j2z8k3r15j569/"
              >
                <i className="si si-info bold mr5"></i>
                {n.name}
              </Link>
              <p className="mt-1">{n.shortText}</p>
            </div>
          );
        })
      ) : (
        <></>
      )}
      <div className="text-right">
        <Link
          className="btn btn-square btn-xs btn-outline-secondary"
          to="/actualites"
        >
          Toutes les actualités
        </Link>
      </div>
    </>
  );
};

export default React.memo(News);
