import { useSelector } from "react-redux";
import { getPersonsSelector } from "../../../redux/selectors";
import TEXT from "../../../constant/text";

const Person = () => {
  const persons = useSelector(getPersonsSelector);
  return (
    <div className="margin-block">
      {persons.map((person) => {
        return (
          <div className="block block-link-pop" key={person.token}>
            <div className="row">
              <div className="col col-12 col-sm-4">
                <div
                  className={`block-content block-content-full text-center ${
                    person.civility.id === 2 ? "bg-smooth" : "bg-modern"
                  }`}
                  style={{ borderRadius: 0 }}
                >
                  <div
                    className="item item-2x item-circle text-center bg-white-10 py-3 my-3 mx-auto js-appear-enabled animated fadeIn"
                    data-toggle="appear"
                    data-offset="50"
                    data-class="animated fadeIn"
                  >
                    <i className="si si-user-following fa-2x text-white-75 text-center"></i>
                  </div>
                </div>
              </div>
              <div className="col col-12 col-sm-8 p0">
                <div className="project-person p14">
                  <h4 className="m0 mb20 bold">
                    <span>
                      {person.civility.name} {person.firstname}{" "}
                      {person.lastname}
                    </span>
                    <a
                      href="/mon-compte/?token=3s9w67505c3gx4yl5rr2"
                      className="btn btn-square btn-xs btn-outline-secondary btn-update-person fr mr10"
                    >
                      Modifier mes informations
                    </a>
                  </h4>
                  <div className="mb15">
                    <div className="fl w50">
                      <i className="si si-envelope fs24"></i>
                    </div>
                    {person.email ? (
                      person.email
                    ) : (
                      <span className="fs12 text-gray font-italic">
                        Information non communiquée
                      </span>
                    )}
                  </div>
                  <div className="mb15">
                    <div className="fl w50">
                      <i className="si si-screen-smartphone fs24"></i>
                    </div>
                    {person.mobile ? (
                      TEXT.wordwrap(person.mobile, 2, " ", true)
                    ) : (
                      <span className="fs12 text-gray font-italic">
                        Information non communiquée
                      </span>
                    )}
                  </div>
                  <div className="">
                    <div className="fl w50">
                      <i className="si si-call-end fs24"></i>
                    </div>
                    <span className="fs12 text-gray font-italic">
                      {person.phone ? (
                        TEXT.wordwrap(person.phone, 2, " ", true)
                      ) : (
                        <span className="fs12 text-gray font-italic">
                          Information non communiquée
                        </span>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Person;
