import { Link } from "react-router-dom";

const Partner = ({ data }) => {
    return (
      <div className="col-6 col-md-4 col-lg-2 div-center text-center">
        {data.url ? (
          <Link to={data.url} target="_blank">
            <img src={data.photo} style={{ height: 80 }} alt={data.name} />
          </Link>
        ) : (
          <img src={data.photo} style={{ height: 80 }} alt={data.name} />
        )}
      </div>
    );
  };

  export default Partner;